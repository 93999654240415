const NodeEnv = Object.freeze({
    DEV: 'development',
    TEST: 'test',
    PROD: 'production'
})
const NODE_ENV = process.env.NODE_ENV;

export default Object.freeze({
    APOLLO: Object.freeze({
        DEVTOOLS: NODE_ENV === NodeEnv.DEV
    }),
    AUTH0: Object.freeze({
        CLIENT_ID: window.env.AUTH0_CLIENT_ID,
        DOMAIN: window.env.AUTH0_DOMAIN,
        AUDIENCE: window.env.AUTH0_AUDIENCE
    })
});