import { Link as RouterLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import LockIcon from '@mui/icons-material/Lock';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import { useAuth0 } from '@auth0/auth0-react';

import AsyncHelmet from '../components/AsyncHelmet';
import LoginButton from '../auth/LoginButton';
import { routes } from '../routes.mjs';

const Home = () => {
    const { isAuthenticated } = useAuth0();

    return (
        <Container sx={{ py: 2 }}>
            <AsyncHelmet text='Home' />
            {isAuthenticated
                ? <Box >
                    <Grid container spacing={3}>
                        <FeatureBox
                            Icon={PlayArrowIcon}
                            primary='Series'
                            secondary='Create and resume series. View statistics by series.'
                            route={routes.series.view}
                        />
                        <FeatureBox
                            Icon={GroupsIcon}
                            primary='Teams'
                            secondary='Manage teams and view team statistics.'
                            route={routes.competitors.view('teams')}
                        />
                        <FeatureBox
                            Icon={PersonIcon}
                            primary='Players'
                            secondary='Manage individual players and view player statistics.'
                            route={routes.competitors.view('players')}
                        />
                    </Grid>
                </Box>
                : <Public />
            }
        </Container>
    )
};

const Public = () => (
    <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
        <LockIcon color='error' sx={{ fontSize: 50, m: 2 }} />
        <Typography variant='h4' align='center'>Spadeschamp is a private site.</Typography>
        <Typography variant='h6' align='center'>You must be logged in to view its content.</Typography>
        <LoginButton color='primary' variant='contained' />
    </Box>
);

const FeatureBox = ({ Icon, primary, secondary, route }) => (
    <Grid item sm={4} xs={12}>
        <Card>
            <CardActionArea component={RouterLink} to={route}>
                <Paper elevation={3}>
                    <Box sx={{ height: 150, p: 2 }} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                        <Icon color='info' sx={{ fontSize: 50 }} />
                        <Typography align='center' variant='h5'>{primary}</Typography>
                        <Typography align='center' variant='body1'>{secondary}</Typography>
                    </Box>
                </Paper>
            </CardActionArea>
        </Card>

    </Grid>
);

export default Home;