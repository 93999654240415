import { useSnackbar } from 'notistack';

export const useSpadesSnackbar = () => {
    const { enqueueSnackbar } = useSnackbar();

    const onError = err => enqueueSnackbar(err.message(), { variant: 'error' });
    const onSuccess = msg => enqueueSnackbar(msg, { variant: 'success' });

    return {
        onError,
        onSuccess,

        // usage: 
        //   withCompletedMessage`Successfully created player ${'name'}.`
        withCompletedMessage: (strings, ...keys) => {
            const template = (...values) => {
                const dict = values[values.length - 1] || {};
                const result = [strings[0]];
                keys.forEach((key, i) => {
                    const value = Number.isInteger(key) ? values[key] : dict[key];
                    result.push(value, strings[i + 1]);
                });
                return result.join('');
            };

            return {
                onError,
                onCompleted: (data) => onSuccess(template(data))
            };
        }
    };
};