import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';

import BasicSelect from '../components/BasicSelect';
import DragDropList from '../components/DragDropList';

const EditDealersDialog = ({ series, handleSave, handleCloseDialog }) => {
    const formControls = useForm({
        defaultValues: {
            dealer: series.dealerId,
            dealers: series.dealers
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return handleSave(data);
    };

    return (
        <DealersDialog
            title='Edit dealers'
            onSave={onSave}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        />
    );
};

export default EditDealersDialog;

const DealersDialog = ({ title, onSave, handleCloseDialog, formControls }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { control, handleSubmit, watch, setValue, formState: { isValid } } = formControls;
    const dealers = watch('dealers');

    const randomDealer = () => {
        const random = Math.floor(Math.random() * dealers.length);
        const d = dealers[random];
        setValue('dealer', d._id);
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ my: 1 }} display='flex' flexDirection='column' gap={2}>
                        <Box display='flex' alignItems='center' gap={1}>
                            <Controller name='dealer' control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <BasicSelect {...field}
                                        items={dealers}
                                        label='Current dealer'
                                    />
                                )}
                            />
                            <Box display='flex' flexDirection='column' alignItems='center'>
                                <IconButton size='small' onClick={() => randomDealer()}><ShuffleIcon /></IconButton>
                                <Typography variant='caption'>random</Typography>
                            </Box>
                        </Box>
                        <Controller name='dealers' control={control}
                            render={({ field }) => (
                                <DealerList dealingOrder={field.value} setDealingOrder={field.onChange} />
                            )}
                        />
                    </Box>
                </DialogContent>
                <Box display='flex' justifyContent='flex-end' gap={1} sx={{ m: 1 }}>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid}
                    >Save</Button>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                </Box>
            </form>
        </Dialog>
    );
};

const DealerList = ({ dealingOrder, setDealingOrder }) => (
    <Box>
        <Typography>Dealing order</Typography>
        <DragDropList items={dealingOrder} setItems={setDealingOrder}>
            {item =>
                <Box display='flex' sx={{ py: 1 }} gap={1}>
                    <MenuIcon />
                    <Typography>{item.name}</Typography>
                </Box>
            }
        </DragDropList>
    </Box>
);