import { Outlet } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';

import { Auth0ProviderWithHistory } from '../auth/Auth0Components';
import AuthorizedApolloProvider from '../auth/AuthorizedApolloProvider';
import NavBar, { FullscreenNavbarProvider } from './NavBar';

const Root = () => {
    return (
        <Auth0ProviderWithHistory>
            <AuthorizedApolloProvider>
                <HelmetProvider>
                    <SnackbarProvider>
                        <FullscreenNavbarProvider>
                            <NavBar />
                            <Outlet />
                        </FullscreenNavbarProvider>
                    </SnackbarProvider>
                </HelmetProvider>
            </AuthorizedApolloProvider>
        </Auth0ProviderWithHistory>
    );
};

export default Root;