import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BlockIcon from '@mui/icons-material/Block';
import SavingsIcon from '@mui/icons-material/Savings';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { yellow, green } from '@mui/material/colors';

import { ScoreFlagEnum } from '../constants';

// Palette: https://mui.com/material-ui/customization/palette/
// Color: https://mui.com/material-ui/customization/color/

const BONUS_COLOR = green[500];
const fontSize = '1rem';
export const WinnerIcon = (props) => <EmojiEventsIcon sx={{ color: yellow[600] }} {...props} />;

export const NilBidPenalty = (props) => <BlockIcon color='error' sx={{ fontSize }} {...props} />;
export const OverBidPenalty = (props) => <ArrowUpwardIcon color='error' sx={{ fontSize }} {...props} />;
export const SandbagPenalty = (props) => <RestartAltIcon color='error' sx={{ fontSize }}  {...props} />;

export const NilBidBonus = (props) => <BlockIcon sx={{ color: BONUS_COLOR, fontSize }} {...props} />;
export const TenBidBonus = (props) => <SavingsIcon sx={{ color: BONUS_COLOR, fontSize }} {...props} />;

export const iconForFlag = (flag, key) => {
    switch (flag) {
        case ScoreFlagEnum.NIL_BID_BONUS:
            return <NilBidBonus key={key} />;
        case ScoreFlagEnum.TEN_BID_BONUS:
            return <TenBidBonus key={key} />;
        case ScoreFlagEnum.NIL_BID_PENALTY:
            return <NilBidPenalty key={key} />;
        case ScoreFlagEnum.OVERBID_PENALTY:
            return <OverBidPenalty key={key} />;
        case ScoreFlagEnum.SANDBAG_PENALTY:
            return <SandbagPenalty key={key} />;
        default:
            return null;
    }
};