import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import BackHandIcon from '@mui/icons-material/BackHand';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { yellow } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import ErrorPanel from '../components/ErrorPanel';
import GenericLoading from '../components/GenericLoading';
import AsyncHelmet from '../components/AsyncHelmet';
import { useNavBar } from '../components/NavBar';
import { useScoreboard } from './useSeries';
import { routes } from '../routes.mjs';
import { iconForFlag } from './SeriesIcons';
import { ScoreDisplay, RecordsDisplay, BidDisplay, typographyScoreFormat } from './ScoreboardComponents';

const Scoreboard = () => {
    const params = useParams();
    const [showRotateTip, setShowRotateTip] = useState(true);
    const { navItems, setNavItems, orientation } = useNavBar();
    const { series, statistics, highlights, error, loading } = useScoreboard(params.id);

    useEffect(() => {
        if (!series || navItems.title === series.name) return;

        setNavItems({ title: series.name, to: routes.series.byId(params.id) })
    }, [params, series, navItems, setNavItems]);

    if (error) return <ErrorPanel error={error} />;

    return (loading
        ? <GenericLoading />
        : <Box display='flex' flexDirection='column'>
            <AsyncHelmet text='Scoreboard' />
            {orientation.startsWith('portrait') && showRotateTip &&
                <Box display='flex' flexDirection='column' alignItems='center' gap={1} sx={{ backgroundColor: yellow[100], mb: 1, p: 1, borderRadius: 2 }}>
                    <Box display='flex' alignItems='flex-start'>
                        <Typography align='center'><strong>Rotate</strong> your device into landscape mode to view the Scoreboard.</Typography>
                        <IconButton onClick={() => setShowRotateTip(false)}><CloseIcon /></IconButton>
                    </Box>
                    <ScreenRotationIcon />
                </Box>

            }
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h3' fontWeight='bold'>{series.gameHandDisplay()}</Typography>
                <Box display='flex' alignItems='center' gap={1}>
                    <BackHandIcon fontSize='large' color='primary' />
                    <Typography variant='h3' color='primary'>{series.dealerName}</Typography>
                </Box>
            </Box>
            <Typography variant='h5'>{series.optionsDisplay()}</Typography>
            <Typography variant='h4' sx={{ my: 4 }}>Remaining books: {series.lastHand.remainingBooks}</Typography>
            <Box display='flex' gap={1}>
                {series.lastGame.activeScores?.map((activeScore, idx) => (
                    <Box key={idx} flexGrow={1} flexBasis={0}>
                        <ScoreBox
                            activeScore={activeScore}
                            statistics={statistics.forCompetitor(activeScore.competitorId)}
                            currentWins={highlights.currentWinsForCompetitor(activeScore.competitorId)}
                            winner={series.lastGame.winnerId === activeScore.competitorId}
                            competitorId={activeScore.competitorId}
                            hands={series.lastGame.reverseHands()}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
export default Scoreboard;

const PreviousHands = ({ hands, competitorId }) => (
    <Box display='flex' flexDirection='column'>
        {hands.map(h => {
            const sh = h.scoredHandForCompetitor(competitorId);
            return (
                <Box key={h._id} display='flex' alignItems='baseline' flexWrap='wrap'>
                    <Box width={30}>
                        <Typography fontWeight='bold'>{h.handNumber}</Typography>
                    </Box>
                    <Box width={45}>
                        <Typography {...typographyScoreFormat(sh.scoreDisplay)}>
                            {sh.scoreDisplay}
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography>{sh.bidActualDisplay()} {sh.flags?.map((f, idx) => iconForFlag(f, idx))}</Typography>
                    </Box>
                    <Box>
                        <Typography variant='caption' color='text.secondary'>{h.endedAtDisplay()}</Typography>
                    </Box>
                </Box>
            );
        }
        )}
    </Box>
);
const ScoreBox = ({ activeScore, statistics, currentWins, winner, hands, competitorId }) => (
    <Paper sx={{ p: 1 }}>
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Box sx={{ mb: 2 }}>
                <BidDisplay activeScore={activeScore} />
            </Box>
            <Typography variant='h2'>{activeScore.competitorName}</Typography>
            <ScoreDisplay activeScore={activeScore} winner={winner} variant='h1' />
            <Box sx={{ mt: 3 }}>
                <RecordsDisplay statistics={statistics} currentWins={currentWins} />
            </Box>
        </Box>
        <Box sx={{ py: 1 }}><hr /></Box>
        <PreviousHands hands={hands} competitorId={competitorId} />
    </Paper >
);