import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const Permission = Object.freeze({
    Delete: 'delete',
    Write: 'write',
    Read: 'read'
});

// this is a work-around to avoid additional api calls in the post-login action in auth0
// because there is no straight-forward way to add permissions to the id_token (only the role)
// note: these permissions are not for authorization (that's done server-side), they are only
// for user experience (e.g., hiding the delete button)
// https://community.auth0.com/t/how-can-i-use-the-management-api-in-actions/64947
const RolePermissionsMap = Object.freeze({
    admin: [Permission.Delete, Permission.Write, Permission.Read],
    player: [Permission.Write, Permission.Read],
    readonly: [Permission.Read]
});

const ROLES_CLAIM = 'https://spadeschamp.com/roles';
const compareArrays = (a, b) => a.length === b.length && a.every(elem => b.includes(elem));

export const useAuth = () => {
    const [permissions, setPermissions] = useState([]);
    const { user, getIdTokenClaims } = useAuth0();

    useEffect(() => {
        const inner = async () => {
            const claims = await getIdTokenClaims();
            const roles = claims?.[ROLES_CLAIM] ?? [];
            const permissionSet = new Set();
            for (const r of roles) {
                RolePermissionsMap[r]?.forEach(p => permissionSet.add(p));
            }
            const asArray = Array.from(permissionSet);
            if (!compareArrays(asArray, permissions)) {
                setPermissions(asArray);
            }
        };
        inner();
    }, [user, permissions, setPermissions, getIdTokenClaims]);

    const hasPermission = (...perms) => perms.some(p => permissions.includes(p));
    return {
        permissions,
        hasPermission
    };
};