import { useQuery, useMutation, gql } from '@apollo/client';
import { ErrorReader } from '../error';

const COMPETITOR_FRAGMENT = `
_id name
... on Team {
    players { _id name }
}
`;

const ALL_COMPETITORS = gql`
query AllCompetitors {
    competitors {
        ${COMPETITOR_FRAGMENT}
    }
}
`;
const COMPETITOR_UPDATE = gql`
mutation CompetitorUpdate($id: ID!, $input: CompetitorInput!) {
    competitorUpdate(id: $id, input: $input) {
        ${COMPETITOR_FRAGMENT}
    }
}
`;
const COMPETITOR_DELETE = gql`
mutation CompetitorDelete($id: ID!) {
    competitorDelete(id: $id)
}
`;

const PLAYER_CREATE = gql`
mutation PlayerCreate($input: PlayerInput!) {
    playerCreate(input: $input) {
        _id name
    }
}
`;
const TEAM_CREATE = gql`
mutation TeamCreate($input: TeamInput!) {
    teamCreate(input: $input) {
        ${COMPETITOR_FRAGMENT}
    }
}
`;

export const useCompetitors = () => {
    const { data, loading, error } = useQuery(ALL_COMPETITORS, {
        fetchPolicy: 'cache-and-network'
    });

    return {
        competitors: data?.competitors,
        players: data?.competitors.filter(c => c.__typename === 'Player'),
        teams: data?.competitors.filter(c => c.__typename === 'Team'),
        loading,
        error
    };
};

export const useCreatePlayer = () => {
    const [playerCreate, playerCreateMutation] = useMutation(PLAYER_CREATE, {
        refetchQueries: ['AllCompetitors']
    });
    return {
        create: (name, { onCompleted, onError } = {}) => playerCreate({
            variables: { input: { name } },
            onCompleted: data => onCompleted?.(data.playerCreate),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('playerCreate', error)
        }),
        loading: playerCreateMutation.loading
    };
};

export const useCreateTeam = () => {
    const [teamCreate, teamCreateMutation] = useMutation(TEAM_CREATE, {
        refetchQueries: ['AllCompetitors']
    });
    return {
        create: (name, players, { onCompleted, onError } = {}) => teamCreate({
            variables: { input: { name, players } },
            onCompleted: data => onCompleted?.(data.teamCreate),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('teamCreate', error)
        }),
        loading: teamCreateMutation.loading
    };
};

export const useEditCompetitor = () => {
    const [competitorUpdate, competitorUpdateMutation] = useMutation(COMPETITOR_UPDATE);
    const [competitorDelete, competitorDeleteMutation] = useMutation(COMPETITOR_DELETE, {
        refetchQueries: ['AllCompetitors']
    });

    return {
        update: (id, name, { onCompleted, onError } = {}) => competitorUpdate({
            variables: { id, input: { name } },
            onCompleted: data => onCompleted?.(data.competitorUpdate),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('competitorUpdate', error)
        }),
        remove: (id, { onCompleted, onError } = {}) => competitorDelete({
            variables: { id },
            onCompleted: data => onCompleted?.(data.competitorDelete),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('competitorDelete', error)
        }),

        loading: competitorUpdateMutation.loading || competitorDeleteMutation.loading
    };
};