import { createElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
        <div role='tabpanel' hidden={value !== index} id={`${index}-tabpanel`}>
            {value === index && (
                <Box sx={{ p: { xs: 0, sm: 3 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const BasicTabs = ({ tabs, panelData, ...rest }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (_, newValue) => {
        searchParams.set('tab', tabs[newValue].slug);
        setSearchParams(searchParams);
    };

    const index = tabs.findIndex(elem => elem.slug === searchParams.get('tab'));
    const tabIndex = index >= 0 ? index : 0;
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} {...rest}>
                    {tabs.map((val, idx) => <Tab key={idx} label={val.title} />)}
                </Tabs>
            </Box>
            {tabs.map((val, idx) =>
                <TabPanel key={idx} value={tabIndex} index={idx}>
                    {createElement(val.component, { data: val.data, ...panelData })}
                </TabPanel>)
            }
        </>
    );
};

export default BasicTabs;