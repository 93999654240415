import { Link as RouterLink } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const SimpleRowComponent = ({ item, handleEdit, generateRoute }) => (
    <ListItem component={RouterLink} to={generateRoute?.(item)} secondaryAction={
        !!handleEdit ? (
            <IconButton edge='end' onClick={(e) => {
                e.preventDefault();
                handleEdit(item);
            }}>
                <EditIcon />
            </IconButton>
        ) : null
    }>
        <Box color='text.primary'>
            <ListItemText primary={item.primary} secondary={item.secondary} />
        </Box>
    </ListItem>
);

// items must contain 3 fields: _id, primary, secondary
const ListWithEdit = ({ loading, items, displayTransform, handleEdit, generateRoute }) => {
    return (
        <List>
            {loading
                ? <CircularProgress />
                : items?.map(item =>
                    <SimpleRowComponent
                        key={item._id}
                        item={displayTransform?.(item) ?? item}
                        handleEdit={handleEdit}
                        generateRoute={generateRoute}
                    />
                )
            }
        </List>
    );
};

export default ListWithEdit;