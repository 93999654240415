import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useApolloClient } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const LogoutLanding = () => {
    const { isAuthenticated, logout } = useAuth0();
    const client = useApolloClient();

    const href = window.location.href;
    useEffect(() => {
        if (!isAuthenticated) return;
        const inner = async () => {
            await client.clearStore();
        };
        inner();
        logout({ logoutParams: { returnTo: href } });
    }, [isAuthenticated, logout, client, href]);

    return (
        <Container sx={{ py: 2 }}>
            <Box display='flex' flexDirection='column' alignItems='center'>
                {isAuthenticated
                    ? <>
                        <Typography variant='h5' align='center'>Logging you out...</Typography>
                        <LogoutIcon sx={{ fontSize: 60 }} />
                    </>
                    : <>
                        <Typography variant='h5' align='center'>Successfully logged out.</Typography>
                        <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color='success' />
                    </>
                }
            </Box>
        </Container>
    );
};

export default LogoutLanding;