import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

const LoginButton = ({ color, variant, size, ...rest }) => {
  const { loginWithRedirect } = useAuth0();
  return <Button
    color={color ?? 'inherit'}
    variant={variant ?? 'text'}
    size={size}
    {...rest}
    onClick={loginWithRedirect}
  >
    Login
  </Button>;
};

export default LoginButton;