import { useQuery, gql } from '@apollo/client';
import { StatisticsModel, } from './stats.model';

const STATISTICS = gql`
query Statistics($competitorIds: [ID!], $seriesId: ID) {
    statistics(competitorIds: $competitorIds, seriesId: $seriesId) {
        competitor { _id name }
        series { _id name }
        
        wins losses seriesPlayed gamesPlayed handsPlayed
        averageBid averageBooks

        exact { percent value total }
        underbid { percent value total }
        overbid { percent value total }
        
        bidStats {
            bid
            exact { percent value total }
            underbid { percent value total }
            overbid { percent value total }
        }
        
        nilBidBonus { percent value total }
        tenBidBonus { percent value total }
        nilBidPenalty { percent value total }
        sandbagPenalty { percent value total }
        overbidPenalty { percent value total }
    }
}
`;

export const useStatistics = (competitorIds, seriesId) => {
    const { data, loading, error } = useQuery(STATISTICS, {
        variables: { competitorIds, seriesId },
        fetchPolicy: 'cache-and-network'
    });

    return {
        statistics: data?.statistics.map(s => new StatisticsModel(s)),
        loading,
        error
    };
};