import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

import ENV from '../env';

// https://nextsteps.dev/apollo-client-graphQL-and-auth
const AuthorizedApolloProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();

    const httpLink = createHttpLink({
        uri: '/api/graphql'
    });

    // eslint-disable-next-line
    const authLink = setContext(async (_, { headers, ...context }) => {
        
        let token = null;
        try {
            token = await getAccessTokenSilently({ audience: ENV.AUTH0.AUDIENCE });
        } catch (e) {
            console.error('error', e);
        }

        const newHeaders = token ? { ...headers, authorization: `Bearer ${token}` } : headers;
        return {
            headers: newHeaders,
            ...context
        };
    });

    const apolloClient = new ApolloClient({
        //connectToDevTools: ENV.APOLLO.DEVTOOLS,
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return (
        <ApolloProvider client={apolloClient}>
            {children}
        </ApolloProvider>
    );
};

export default AuthorizedApolloProvider;