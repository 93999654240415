import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { ScoreFlagEnum } from '../constants';

const ScoreOverrideEnum = Object.freeze({
    Manual: 'manual',
    Automatic: 'automatic'
});

const EditScoreDialog = ({ series, scoredHand, handleSave, handleCloseDialog }) => {

    const flags = Object.values(ScoreFlagEnum).reduce((accum, val) => {
        accum[val] = scoredHand.hasFlag(val);
        return accum;
    }, {});

    const formControls = useForm({
        defaultValues: {
            scoreOverride: scoredHand.scoreOverride ? ScoreOverrideEnum.Manual : ScoreOverrideEnum.Automatic,
            score: scoredHand.score ?? 0,
            sandbag: scoredHand.sandbag ?? 0,
            ...flags
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        const input = {
            scoreOverride: data.scoreOverride === ScoreOverrideEnum.Manual,
            score: data.score,
            sandbag: data.sandbag,
            flags: Object.values(ScoreFlagEnum).filter(v => data[v])
        };
        return handleSave(series._id, scoredHand.competitorId, input);
    };

    return (
        <ScoreDialog
            title={`Edit score for ${scoredHand.competitorName}`}
            sandbagMax={series.lastGame.options.sandbagPenaltyLimit - 1}
            onSave={onSave}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        />
    );
};

export default EditScoreDialog;

const ScoreDialog = ({ title, sandbagMax, onSave, handleCloseDialog, formControls }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { control, handleSubmit, watch, formState: { isValid } } = formControls;
    const scoreOverride = watch('scoreOverride');

    // Calculate score: toggle (automatic, manual)
    // Score: stepper by 10
    // Sandbag: stepper by 1 (limit to sandbagPenaltyLimit - 1)
    // Nil bid bonus: switch
    // Ten bid bonus: switch
    // Overbid penalty: switch
    // Sandbag penalty: switch
    // Nil bid penalty: switch
    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ my: 1 }} display='flex' flexDirection='column' gap={1}>
                        <Controller name='scoreOverride' control={control}
                            render={({ field }) => (
                                <ScoreOverrideToggle field={field} />
                            )}
                        />
                        {scoreOverride === ScoreOverrideEnum.Manual &&
                            <>
                                <Controller name='score' control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <NumberStepper field={field} incr={10} />
                                            }
                                            label='Score'
                                        />
                                    )}
                                />
                                <Controller name='sandbag' control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <NumberStepper field={field} incr={1} min={0} max={sandbagMax} />
                                            }
                                            label='Sandbag'
                                        />
                                    )}
                                />
                                <Box><hr /></Box>
                                <Controller name={ScoreFlagEnum.NIL_BID_BONUS} control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch checked={field.value} {...field} />
                                            }
                                            label='Nil bid bonus'
                                        />
                                    )}
                                />
                                <Controller name={ScoreFlagEnum.TEN_BID_BONUS} control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch checked={field.value} {...field} />
                                            }
                                            label='Ten bid bonus'
                                        />
                                    )}
                                />
                                <Box><hr /></Box>
                                <Controller name={ScoreFlagEnum.OVERBID_PENALTY} control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch checked={field.value} {...field} />
                                            }
                                            label='Overbid penalty'
                                        />
                                    )}
                                />
                                <Controller name={ScoreFlagEnum.SANDBAG_PENALTY} control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch checked={field.value} {...field} />
                                            }
                                            label='Sandbag penalty'
                                        />
                                    )}
                                />
                                <Controller name={ScoreFlagEnum.NIL_BID_PENALTY} control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Switch checked={field.value} {...field} />
                                            }
                                            label='Nil bid penalty'
                                        />
                                    )}
                                />
                            </>
                        }
                    </Box>
                </DialogContent>
                <Box display='flex' justifyContent='flex-end' gap={1} sx={{ m: 1 }}>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid}
                    >Save</Button>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                </Box>
            </form>
        </Dialog>
    );
};

const ScoreOverrideToggle = ({ field }) => (
    <ToggleButtonGroup
        color='primary'
        size='small'
        exclusive
        {...field}
    >
        <ToggleButton value={ScoreOverrideEnum.Automatic}>Automatic</ToggleButton>
        <ToggleButton value={ScoreOverrideEnum.Manual}>Manual</ToggleButton>
    </ToggleButtonGroup>
);

const NumberStepper = ({ field, incr, min, max }) => (
    <Box display='flex' alignItems='center'>
        <IconButton onClick={() => typeof min !== 'number' || field.value - incr >= min ? field.onChange(field.value - incr) : null}>
            <KeyboardArrowDownIcon />
        </IconButton>
        <Typography>{field.value}</Typography>
        <IconButton onClick={() => typeof max !== 'number' || field.value + incr <= max ? field.onChange(field.value + incr) : null}>
            <KeyboardArrowUpIcon />
        </IconButton>
    </Box>
);