import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const BasicSelect = React.forwardRef(({ label, items, ...rest }, ref) => {
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select label={label} {...rest} ref={ref}>
                {items?.map(i => <MenuItem key={i._id} value={i._id}>{i.name}</MenuItem>)}
            </Select>
        </FormControl>
    );
});

export default BasicSelect;