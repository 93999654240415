import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import AsyncHelmet from '../components/AsyncHelmet';
import ErrorPanel from '../components/ErrorPanel';
import { useStatistics } from '../statistics/useStatistics';
import GenericLoading from '../components/GenericLoading';
import Statistics from '../statistics/Statistics';
import BasicTabs from '../components/BasicTabs';

const StatisticsWrapper = ({ data }) => <Statistics statistics={data} />;

const SeriesStatistics = () => {
    const params = useParams();
    const { statistics, error, loading } = useStatistics(null, params.id);

    if (error) return <ErrorPanel error={error} />;

    const tabs = statistics?.map(s => ({
        slug: s.competitorId,
        title: s.competitorName,
        component: StatisticsWrapper,
        data: s
    }));

    const seriesName = statistics?.at(0).seriesName;
    return (loading
        ? <GenericLoading />
        : <>
            <AsyncHelmet text={`${seriesName} - Statistics`} />
            <Typography variant='h4'>{seriesName}</Typography>
            <Typography variant='h6'>Series statistics</Typography>
            <BasicTabs tabs={tabs} variant='fullWidth' />
        </>
    );
};

export default SeriesStatistics;