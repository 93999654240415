import dayjs from 'dayjs';

export class SummaryModel {
    constructor(summary) {
        this.summary = summary;
    }

    dateRange() {
        const startDateFormat = dayjs(this.summary.dateStarted).format('MMM D, YYYY');
        const endDate = dayjs(this.summary.dateEnded);
        const endDateFormat = endDate.format('MMM D, YYYY');

        return startDateFormat !== endDateFormat && endDate.isValid()
            ? `${startDateFormat} - ${endDateFormat}`
            : startDateFormat;
    }
    playCount() {
        const gameLabel = this.summary.gamesPlayed === 1 ? 'game' : 'games';
        const handLabel = this.summary.handsPlayed === 1 ? 'hand' : 'hands';
        return `${this.summary.gamesPlayed} ${gameLabel} / ${this.summary.handsPlayed} ${handLabel}`;
    }
}

export class StatisticsModel {
    constructor(statistics) {
        this.statistics = statistics;
    }

    get competitorId() {
        return this.statistics.competitor._id;
    }
    get competitorName() {
        return this.statistics.competitor.name;
    }
    get seriesId() {
        return this.statistics.series?._id;
    }
    get seriesName() {
        return this.statistics.series?.name;
    }

    get wins() {
        return this.statistics.wins;
    }
    get losses() {
        return this.statistics.losses;
    }
    get seriesPlayed() {
        return this.statistics.seriesPlayed;
    }
    get gamesPlayed() {
        return this.statistics.gamesPlayed;
    }
    get handsPlayed() {
        return this.statistics.handsPlayed;
    }
    get averageBid() {
        return this.statistics.averageBid;
    }
    get averageBooks() {
        return this.statistics.averageBooks;
    }

    get exact() {
        return this.statistics.exact;
    }
    get underbid() {
        return this.statistics.underbid;
    }
    get overbid() {
        return this.statistics.overbid;
    }
    get bidStats() {
        return this.statistics.bidStats;
    }

    get nilBidBonus() {
        return this.statistics.nilBidBonus;
    }
    get tenBidBonus() {
        return this.statistics.tenBidBonus;
    }
    get nilBidPenalty() {
        return this.statistics.nilBidPenalty;
    }
    get sandbagPenalty() {
        return this.statistics.sandbagPenalty;
    }
    get overbidPenalty() {
        return this.statistics.overbidPenalty;
    }

    isTeam() {
        return this.statistics.competitor.__typename === 'Team';
    }
    winsDisplay() {
        return `${this.statistics.wins} ${this.statistics.wins === 1 ? 'win' : 'wins'}`;
    }
    teamPlayers() {
        return this.statistics.competitor.players?.map(p => p.name).join(', ');
    }
}
export class StatisticsListModel {
    constructor(statistics) {
        // array of statistics
        this._statistics = statistics.map(s => new StatisticsModel(s));
    }

    get list() {
        return this._statistics;
    }

    forCompetitor(competitorId) {
        return this._statistics.find(item => item.competitorId === competitorId);
    }
}

export class HighlightsModel {

    static isNight(ts) {
        return dayjs(ts).hour() > 16 || dayjs(ts).hour() <= 4;
    }

    constructor(highlights) {
        this._highlights = highlights;
        this._records = [...highlights.records];
        this._records.sort((a, b) => a.competitor.name.localeCompare(b.competitor.name));
    }

    get dateStarted() {
        return this._highlights.dateStarted;
    }
    get dateEnded() {
        return this._highlights.dateEnded;
    }

    get gamesPlayed() {
        return this._highlights.gamesPlayed;
    }
    get handsPlayed() {
        return this._highlights.handsPlayed;
    }

    get competitorRecords() {
        return this._records;
    }
    get competitorCount() {
        return this.competitorRecords.length;
    }

    forCompetitor(competitorId) {
        return this.competitorRecords.find(h => h.competitor._id === competitorId);
    }
    highlightsHeaderDisplay(index) {
        if (HighlightsModel.isNight(this._highlights.dateEnded)) {
            return `Night ${index + 1}`;
        }
        else {
            return `Day ${index + 1}`;
        }
    }
    highlightsHeaderTimeDisplay() {
        const start = dayjs(this._highlights.dateStarted);
        const end = dayjs(this._highlights.dateEnded);

        const format = 'ddd, h:mm a';
        let display = null;
        if (start.date() !== end.date()) {
            display = `${start.format(format)} - ${end.format(format)}`;
        } else {
            display = `${start.format(format)} - ${end.format('h:mm a')}`;
        }
        return display;
    }
    playCount() {
        return new SummaryModel(this._highlights).playCount();
    }
}

export const HighlightGroupEnum = Object.freeze({ NONE: 'none', NIGHT: 'night', DAY: 'day' });

export class HighlightsListModel {

    constructor(highlights) {
        this._highlights = highlights.map(h => new HighlightsModel(h));
    }

    get list() {
        return this._highlights;
    }
    hasHighlights() {
        return this._highlights.length > 0;
    }

    calculateHighlightsGroupType() {
        if (this._highlights.every(h => HighlightsModel.isNight(h.dateEnded))) {
            return HighlightGroupEnum.NIGHT;
        }
        else if (this._highlights.every(h => !HighlightsModel.isNight(h.dateEnded))) {
            return HighlightGroupEnum.DAY;
        }
        else {
            return HighlightGroupEnum.NONE;
        }
    }

    currentWinsForCompetitor(competitorId) {
        const now = dayjs();
        for (const h of this._highlights) {
            if (now.diff(dayjs(h.dateEnded), 'hour') <= 6) {
                return h.forCompetitor(competitorId).wins;
            }
        }
        return 0;
    }

    highlightsTitleDisplay() {
        switch (this.calculateHighlightsGroupType()) {
            case HighlightGroupEnum.NIGHT:
                return 'Nightly summary';
            case HighlightGroupEnum.DAY:
                return 'Daily summary';
            default:
                return 'Series summary';
        }
    }
}