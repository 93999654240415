import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import AsyncHelmet from '../components/AsyncHelmet';
import ErrorPanel from '../components/ErrorPanel';
import { useSeriesSummary } from './useSeries';
import Highlights from './Highlights';
import GenericLoading from '../components/GenericLoading';
import { routes } from '../routes.mjs';
import { useAuth, Permission } from '../auth/useAuth';

const Vs = () => <Typography variant='body2'>vs</Typography>;

const Statistics = ({ statistics }) => {

    // insert Vs component between competitors
    const components = [...statistics.list];
    for (let i = 0; i < statistics.list.length - 1; ++i) {
        components.splice(i * 2 + 1, 0, null);
    }

    return (
        <Box display='flex' alignItems='center' gap={1} sx={{ my: 2 }}>
            {components.map((c, idx) => c
                ? <Box key={c.competitorId} flexGrow={1} flexBasis={0}>
                    <Box component={RouterLink} sx={{ textDecoration: 'none' }} to={routes.series.stats(c.seriesId, c.competitorId)}>
                        <Paper sx={{ pt: 2 }}>
                            <Box display='flex' flexDirection='column' alignItems='center'>
                                <Typography variant='h6'><strong>{c.competitorName}</strong></Typography>
                                <Box alignSelf='stretch' sx={{ px: 3 }}><hr /></Box>
                                <Typography variant='body1'>{c.winsDisplay()}</Typography>
                                <Typography variant='caption' sx={{ mt: 1 }} color='text.secondary' align='center'>click to view stats</Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                : <Box key={idx} flexGrow={0}><Vs /></Box>
            )}
        </Box>
    );
};

const SeriesSummary = () => {
    const params = useParams();
    const { series, highlights, statistics, summary, error, loading } = useSeriesSummary(params.id);
    const { hasPermission } = useAuth();

    if (error) return <ErrorPanel error={error} />;

    return (loading
        ? <GenericLoading />
        : <>
            <AsyncHelmet text={series.name} />
            <Typography variant='h4'>{series.name}</Typography>
            <Typography variant='subtitle2'>{summary.dateRange()}</Typography>
            <Typography variant='subtitle1'>{summary.playCount()}</Typography>
            {series.isActive() &&
                <Box display='flex' gap={1} sx={{ my: 2 }}>
                    {hasPermission(Permission.Write) &&
                        <Button
                            variant='contained'
                            fullWidth={true}
                            component={RouterLink}
                            to={routes.fullscreen.play(params.id)}
                        >Keep Score</Button>
                    }
                    <Button
                        variant='contained'
                        fullWidth={true}
                        color='error'
                        component={RouterLink}
                        to={routes.fullscreen.scoreboard(params.id)}
                    >Scoreboard</Button>
                </Box>
            }

            <Statistics statistics={statistics} />

            {highlights?.hasHighlights() &&
                <>
                    <hr />
                    <Highlights highlights={highlights} seriesId={series._id} />
                </>
            }
        </>
    );
};

export default SeriesSummary;