import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ConfirmationDialog = ({ title, message, destructiveButtonLabel, handleConfirm, handleCloseDialog }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const handleConfirmWrapper = () => {
        handleCloseDialog();
        return handleConfirm();
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            {title &&
                <DialogTitle>{title}</DialogTitle>
            }
            {message &&
                <DialogContent>{message}</DialogContent>
            }
            <Box display='flex' justifyContent='flex-end' sx={{ mx: 2, mb: 1 }} gap={1}>
                <Button
                    variant='contained'
                    color='error'
                    onClick={handleConfirmWrapper}
                >{destructiveButtonLabel ?? 'Delete'}</Button>
                <Button onClick={handleCloseDialog}>Cancel</Button>
            </Box>
        </Dialog>
    );
};

export default ConfirmationDialog;