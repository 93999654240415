import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Typography from '@mui/material/Typography';

const LoginRedirect = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) return;
        loginWithRedirect();
    }, [isAuthenticated, loginWithRedirect]);

    return (
        <Typography variant='body2'>Redirecting...</Typography>
    );
};

export default LoginRedirect;