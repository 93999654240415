import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';

import AsyncHelmet from '../components/AsyncHelmet';
import ErrorPanel from '../components/ErrorPanel';
import GenericLoading from '../components/GenericLoading';
import { WinnerIcon, iconForFlag } from './SeriesIcons';
import { useSeriesById } from './useSeries';

// +1 to include the handNumber column
const calculateColSpan = (series) => (series?.competitorCount ?? 0) + 1;

const Flags = ({ scoredHand }) => (
    scoredHand.hasFlags()
        ? <Box display='flex'>
            {scoredHand.flags.map((f, idx) => iconForFlag(f, idx))}
        </Box>
        : null
);

const ScoredHandCell = ({ scoredHand, ...rest }) => (
    <TableCell {...rest}>
        <Box>
            <Typography variant='body1' color={scoredHand.isNegativeScore() ? 'error' : undefined}>{scoredHand.scoreDisplay}</Typography>
            <Box display='flex' gap={1}>
                <Typography variant='caption'>{scoredHand.bidActualDisplay()}</Typography>
                <Flags scoredHand={scoredHand} />
            </Box>
        </Box>
    </TableCell>
);

const UnwrapScoredHand = ({ hand, competitor, children }) => (
    children(hand.scoredHandForCompetitor(competitor._id))
);
const HandRow = ({ hand, competitors, colSpan }) => (
    <>
        <TableRow>
            <TableCell sx={{ borderWidth: 0, pb: 0 }}>
                {hand.handNumber}
            </TableCell>
            {competitors.map(c => (
                <UnwrapScoredHand key={c._id} hand={hand} competitor={c}>
                    {sh => <ScoredHandCell scoredHand={sh} sx={{ borderWidth: 0, pb: 0 }} />}
                </UnwrapScoredHand>
            ))}
        </TableRow>
        <TableRow>
            <TableCell colSpan={colSpan} sx={{ pt: 0 }}>
                <Typography variant='caption' color='text.secondary'><em>{hand.footer()}</em></Typography>
            </TableCell>
        </TableRow>
    </>
);

export const HandSection = ({ series, game, order }) => {
    const colSpan = calculateColSpan(series);

    // ensure competitor order consistency
    const competitors = series.competitors;

    // reverse hand order if order < 0
    const hands = [...game.hands];
    if (order < 0) {
        hands.reverse();
    }
    return (
        <>
            <TableRow>
                <TableCell>#</TableCell>
                {competitors.map(c => (
                    <TableCell key={c._id}><strong>{c.name}</strong></TableCell>
                ))}
            </TableRow>
            {
                hands.map(hand => (
                    <HandRow key={hand._id} hand={hand} competitors={competitors} colSpan={colSpan} />
                ))
            }
        </>
    );
};

const GameSection = ({ series, game }) => {
    const colSpan = calculateColSpan(series);
    return (
        <>
            <TableRow sx={{ bgcolor: green[50] }}>
                <TableCell colSpan={colSpan}>
                    <Box>
                        <Typography variant='subtitle1'><strong>{game.gameDisplay()}</strong></Typography>
                        <Typography variant='subtitle2'>{game.updatedAtDisplay()}</Typography>
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={colSpan}>
                    <Box>
                        {game.hasWinner() &&
                            <Box display='flex' alignContent='center' gap={1}>
                                <WinnerIcon />
                                <Typography variant='subtitle1'>{game.winnerName}</Typography>
                            </Box>
                        }
                        {game.inProgress() &&
                            <Typography variant='caption'>In progress</Typography>
                        }
                        {game.isAbandoned() &&
                            <Typography variant='caption'>Abandoned</Typography>
                        }
                    </Box>
                </TableCell>
            </TableRow>
            <HandSection series={series} game={game} />
        </>
    );
};

const SeriesHistory = () => {
    const params = useParams();
    const { series, error, loading } = useSeriesById(params.id);

    if (error) return <ErrorPanel error={error} />;

    return (loading
        ? <GenericLoading />
        : <>
            <AsyncHelmet text={`${series.name} - History`} />
            <Typography variant='h6'>{series.name}</Typography>
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableBody>
                        {series.games.map((game) => (
                            <GameSection
                                key={game._id}
                                series={series}
                                game={game}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
};

export default SeriesHistory;