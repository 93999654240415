import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';

import { NilBidBonus, TenBidBonus, NilBidPenalty, OverBidPenalty, SandbagPenalty } from '../series/SeriesIcons';

const Statistics = ({ statistics }) => {
    return (
        <TableContainer component={Paper}>
            <Table size='small'>
                <TableBody>
                    <Header>Played</Header>
                    <Played stat={statistics} />

                    <Header>Record</Header>
                    <Record stat={statistics} />

                    <Header>Overall Accuracy</Header>
                    <Accuracy stat={statistics} />

                    <Header>Bidding</Header>
                    <Averages stat={statistics} />
                    {statistics.bidStats.map(item =>
                        <Bidding key={item.bid} bidStat={item} />
                    )}

                    <Header>Bonuses</Header>
                    <BonusOrPenalty name='Nil bid' icon={<NilBidBonus />} stat={statistics.nilBidBonus} />
                    <BonusOrPenalty name='Ten bid' icon={<TenBidBonus />} stat={statistics.tenBidBonus} />

                    <Header>Penalties</Header>
                    <BonusOrPenalty name='Nil bid' icon={<NilBidPenalty />} stat={statistics.nilBidPenalty} />
                    <BonusOrPenalty name='Overbid (set)' icon={<OverBidPenalty />} stat={statistics.overbidPenalty} />
                    <BonusOrPenalty name='Sandbag (reset)' icon={<SandbagPenalty />} stat={statistics.sandbagPenalty} />

                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default Statistics;

const Row = ({ children, ...rest }) => (
    <TableRow {...rest}>
        {children}
    </TableRow>
);
const Cell = ({ children, ...rest }) => (
    <TableCell scope='row' {...rest}>
        {children}
    </TableCell>
);
const FlexCell = ({ children, ...rest }) => (
    <Row>
        <Cell {...rest}>
            <Box display='flex' justifyContent='space-around' alignItems='center'>
                {children}
            </Box>
        </Cell>
    </Row>
);

const Header = ({ children }) => (
    <Row sx={{ bgcolor: green[50] }}>
        <Cell><strong>{children}</strong></Cell>
    </Row>
);

const ColumnStack = ({ title, value }) => (
    <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography variant='body2'><strong>{title}</strong></Typography>
        <Typography variant='body1'>{value}</Typography>
    </Box>
);

const isNumber = value => typeof value === 'number';
const pct = value => isNumber(value) ? `${(value * 100).toFixed()}%` : '--';
const PercentTuple = ({ tuple }) => (
    <Box display='flex' alignItems='baseline' component='span'>
        <Typography variant='body1' component='span'>{pct(tuple.percent)}</Typography>
        {isNumber(tuple.percent) &&
            <Typography sx={{ ml: 0.5 }} variant='body1' component='span'>({tuple.value})</Typography>
        }
    </Box>
);

const Played = ({ stat }) => (
    <FlexCell>
        <ColumnStack title='Series' value={stat.seriesPlayed} />
        <ColumnStack title='Games' value={stat.gamesPlayed} />
        <ColumnStack title='Hands' value={stat.handsPlayed} />
    </FlexCell>
);
const Record = ({ stat }) => (
    <Row>
        <Cell>
            <Typography>{`${stat.wins} (W) / ${stat.losses} (L)`}</Typography>
        </Cell>
    </Row>
);
const Accuracy = ({ stat }) => (
    <FlexCell>
        <ColumnStack title='Under' value={<PercentTuple tuple={stat.underbid} />} />
        <ColumnStack title='Exact' value={<PercentTuple tuple={stat.exact} />} />
        <ColumnStack title='Over' value={<PercentTuple tuple={stat.overbid} />} />
    </FlexCell>
);
const Averages = ({ stat }) => (
    <FlexCell>
        <ColumnStack title='Avg. Bid' value={stat.averageBid?.toFixed(1) ?? '--'} />
        <ColumnStack title='Avg. Books' value={stat.averageBooks?.toFixed(1) ?? '--'} />
    </FlexCell>
);
const Bidding = ({ bidStat }) => (
    <FlexCell>
        <Typography variant='body1'>{bidStat.bid}</Typography>
        <ColumnStack title='Under' value={<PercentTuple tuple={bidStat.underbid} />} />
        <ColumnStack title='Exact' value={<PercentTuple tuple={bidStat.exact} />} />
        <ColumnStack title='Over' value={<PercentTuple tuple={bidStat.overbid} />} />
        <ColumnStack title='Att' value={bidStat.exact.total} />
    </FlexCell>
);
const BonusOrPenalty = ({ name, icon, stat }) => (
    <Row>
        <Cell>
            <Box display='flex' alignItems='baseline' gap={1}>
                {icon}
                <Typography variant='body2'><strong>{name}</strong></Typography>
                <Typography variant='body1'>{pct(stat.percent)}</Typography>
                <Typography variant='body1'>({stat.value}/{stat.total})</Typography>
            </Box>
        </Cell>
    </Row>
);