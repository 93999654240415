import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { routes } from '../routes.mjs';

const Highlights = ({ highlights, seriesId }) => (
    <Box display='flex' flexDirection='column' gap={1}>
        <Typography variant='h5'>{highlights.highlightsTitleDisplay()}</Typography>
        <Box>
            <Button component={RouterLink} size='small' variant='outlined' to={routes.series.history(seriesId)}>Score History</Button>
        </Box>
        {highlights.list.map((hl, idx) => (
            <Box key={idx} display='flex' flexDirection='column'>
                {/* HEADER */}
                <Box display='flex' flexDirection='column' sx={{ bgcolor: green[50], px: 1 }}>
                    <Typography variant='subtitle1'><strong>{hl.highlightsHeaderDisplay(idx)}</strong></Typography>
                    <Typography variant='subtitle2'>{hl.highlightsHeaderTimeDisplay()}</Typography>
                </Box>
                {/* BODY */}
                <Box display='flex' flexDirection='row' sx={{ px: 1 }}>
                    {hl.competitorRecords.map((c, idx) => (
                        <Box key={idx} display='flex'>
                            <Typography variant='body1'>{c.competitor.name} ({c.wins})</Typography>
                            {idx < hl.competitorCount - 1 &&
                                <Typography variant='body1' sx={{ mx: 1 }}>vs</Typography>
                            }
                        </Box>
                    ))}
                </Box>
                <Typography variant='body2' sx={{ px: 1 }}>{hl.playCount()}</Typography>
            </Box>
        ))}
    </Box>
);

export default Highlights;