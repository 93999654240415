import { useMutation, gql } from '@apollo/client';
import { ErrorReader } from '../error';
import { SERIES_FRAGMENT } from './useSeries';

const SET_SCORE_PARAMETERS = gql`
mutation SetScoreParameters($seriesId: ID!, $competitorId: ID!, $input: ScoreParametersInput!, $active: Boolean) {
    setScoreParameters(seriesId: $seriesId, competitorId: $competitorId, input: $input) { ${SERIES_FRAGMENT} }
}
`;

const COMPLETE_HAND = gql`
mutation CompleteHand($seriesId: ID!, $active: Boolean) {
    completeHand(seriesId: $seriesId) { ${SERIES_FRAGMENT} }
}
`;

const COMPLETE_GAME = gql`
mutation CompleteGame($seriesId: ID!, $reason: CompleteGameInput, $active: Boolean) {
    completeGame(seriesId: $seriesId, reason: $reason) { ${SERIES_FRAGMENT} }
}
`;

const SET_GAME_OPTIONS = gql`
mutation SetGameOptions($seriesId: ID!, $input: GameOptionsInput!, $active: Boolean) {
    setGameOptions(seriesId: $seriesId, input: $input) { ${SERIES_FRAGMENT} }
}
`;

const SET_DEALER = gql`
mutation SetDealer($seriesId: ID!, $playerId: ID!, $active: Boolean) {
    setDealer(seriesId: $seriesId, playerId: $playerId) { ${SERIES_FRAGMENT} }
}
`;

const SET_SCORE_OVERRIDE = gql`
mutation SetScoreOverride($seriesId: ID!, $competitorId: ID!, $input: ScoreOverrideInput!, $active: Boolean) {
    setScoreOverride(seriesId: $seriesId, competitorId: $competitorId, input: $input) { ${SERIES_FRAGMENT} }
}
`;

const EDIT_LAST_HAND = gql`
mutation EditLastHand($seriesId: ID!, $active: Boolean) {
    editLastHand(seriesId: $seriesId) { ${SERIES_FRAGMENT} }
}
`;

export const useScoreParameters = () => {
    const [setScoreParameters, setScoreParametersMutation] = useMutation(SET_SCORE_PARAMETERS);

    return {
        setScoreParameters: (seriesId, competitorId, input, { onCompleted, onError } = {}) => setScoreParameters({
            variables: { seriesId, competitorId, input, active: true },
            onCompleted: data => onCompleted?.(data.setScoreParameters),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('setScoreParameters', error)
        }),
        loading: setScoreParametersMutation.loading
    };
};

export const useComplete = () => {
    const [completeHand, completeHandMutation] = useMutation(COMPLETE_HAND);
    const [completeGame, completeGameMutation] = useMutation(COMPLETE_GAME, {
        refetchQueries: ['SeriesById']
    });

    return {
        completeHand: (seriesId, { onCompleted, onError } = {}) => completeHand({
            variables: { seriesId },
            onCompleted: data => onCompleted?.(data.completeHand),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('completeHand', error)
        }),
        completeGame: (seriesId, reason, { onCompleted, onError } = {}) => completeGame({
            variables: { seriesId, reason },
            onCompleted: data => onCompleted?.(data.completeGame),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('completeGame', error)
        }),

        loading: completeHandMutation.loading || completeGameMutation.loading
    };
};

export const useSetGameOptions = () => {
    const [setGameOptions, setGameOptionsMutation] = useMutation(SET_GAME_OPTIONS);
    return {
        setGameOptions: (seriesId, input, { onCompleted, onError } = {}) => setGameOptions({
            variables: { seriesId, input, active: true },
            onCompleted: data => onCompleted?.(data.setGameOptions),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('setGameOptions', error)
        }),

        loading: setGameOptionsMutation.loading
    };
};

export const useEditDealers = () => {
    const [setDealer, setDealerMutation] = useMutation(SET_DEALER);
    return {
        setDealer: (seriesId, playerId, { onCompleted, onError } = {}) => setDealer({
            variables: { seriesId, playerId, active: true },
            onCompleted: data => onCompleted?.(data.setDealer),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('setDealer', error)
        }),

        loading: setDealerMutation.loading
    };
};

export const useSetScoreOverride = () => {
    const [setScoreOverride, setScoreOverrideMutation] = useMutation(SET_SCORE_OVERRIDE);
    return {
        setScoreOverride: (seriesId, competitorId, input, { onCompleted, onError } = {}) => setScoreOverride({
            variables: { seriesId, competitorId, input, active: true },
            onCompleted: data => onCompleted?.(data.setScoreOverride),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('setScoreOverride', error)
        }),

        loading: setScoreOverrideMutation.loading
    };
};

export const useEditLastHand = () => {
    const [editLastHand, editLastHandMutation] = useMutation(EDIT_LAST_HAND);
    return {
        editLastHand: (seriesId, { onCompleted, onError } = {}) => editLastHand({
            variables: { seriesId, active: true },
            onCompleted: data => onCompleted?.(data.editLastHand),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('editLastHand', error)
        }),

        loading: editLastHandMutation.loading
    };
};