import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { HighlightsModel } from '../statistics/stats.model';
import { WinnerIcon } from './SeriesIcons';

export const typographyScoreFormat = score => score.startsWith('-') ? { color: 'error' } : undefined;

export const ScoreDisplay = ({ activeScore, winner, variant }) => (
    <Box display='flex' gap={0.5} alignItems='center' flexWrap='wrap' justifyContent='center'>
        <Typography variant={variant ?? 'h5'} {...typographyScoreFormat(activeScore.currentScore)}>{activeScore.currentScore}</Typography>
        {activeScore.proposedScore &&
            <>
                <ArrowRightAltIcon fontSize={variant ? 'large' : undefined} />
                <Typography variant={variant ?? 'h5'} {...typographyScoreFormat(activeScore.proposedScore)}>{activeScore.proposedScore}</Typography>
                {winner &&
                    <WinnerIcon />
                }
            </>
        }
    </Box>
);
export const RecordsDisplay = ({ statistics, currentWins }) => (
    <Box display='flex' gap={3}>
        {currentWins >= 0 &&
            <Box display='flex' flexDirection='column'>
                <Typography variant='body1' align='center'>{currentWins}</Typography>
                <Typography variant='body1' align='center'>{HighlightsModel.isNight(new Date().getTime()) ? 'tonight' : 'today'}</Typography>
            </Box>
        }
        {statistics &&
            <Box display='flex' flexDirection='column'>
                <Typography variant='body1' align='center'>{statistics.wins}</Typography>
                <Typography variant='body1' align='center'>overall</Typography>
            </Box>
        }
    </Box>
);
export const BidDisplay = ({ activeScore }) => (
    <Box display='flex' gap={1} alignItems='center' color={!activeScore.bidDisplay ? 'text.secondary' : undefined}>
        <Typography variant='h1'>{activeScore.bidDisplay ?? '--'}</Typography>
        <Typography variant='body1'>bid</Typography>
    </Box>
);