import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import AsyncHelmet from '../components/AsyncHelmet';
import ErrorPanel from '../components/ErrorPanel';
import { useStatistics } from '../statistics/useStatistics';
import GenericLoading from '../components/GenericLoading';
import Statistics from '../statistics/Statistics';

const CompetitorStatistics = () => {
    const params = useParams();
    const { statistics, error, loading } = useStatistics([params.id]);

    if (error) return <ErrorPanel error={error} />;

    const stat = statistics?.at(0);
    return (loading
        ? <GenericLoading />
        : <>
            <AsyncHelmet text={`${stat.competitorName} - Statistics`} />
            <Typography variant='h4'>{stat.competitorName}</Typography>
            <Typography variant='h6'>Competitor statistics</Typography>
            <Statistics statistics={stat} />
        </>
    );
};

export default CompetitorStatistics;