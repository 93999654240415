import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Tooltip from '@mui/material/Tooltip';

import AsyncHelmet from '../components/AsyncHelmet';
import { useEditSeries, useSeriesBasic, useSeriesCreate } from './useSeries';
import ErrorPanel from '../components/ErrorPanel';
import { routes } from '../routes.mjs';
import EditSeriesDialog from './EditSeriesDialog';
import { useSpadesSnackbar } from '../components/useSpadesSnackbar';
import ConfirmationDialog from '../components/ConfirmationDialog';
import NewSeriesDialog from './NewSeriesDialog';
import { useAuth, Permission } from '../auth/useAuth';

export const SeriesRow = ({ series, handleEdit }) => (
    <ListItem component={RouterLink} to={routes.series.byId(series._id)} secondaryAction={
        !!handleEdit ? (
            <IconButton edge='end' onClick={(e) => {
                e.preventDefault();
                handleEdit(series);
            }}>
                <EditIcon />
            </IconButton>
        ) : null
    }>
        <ListItemText component='div'
            primary={
                <Box display='flex' color='text.primary' alignItems='center' gap={1}>
                    <Typography variant='h5' component='span' color='inherit'>{series.name}</Typography>
                    {!series.isActive() &&
                        <Tooltip title='Series complete'>
                            <TaskAltIcon fontSize='small' color='success' />
                        </Tooltip>
                    }
                </Box>
            }
            secondary={
                <Box display='flex' flexDirection='column' alignItems='baseline' component='span'>
                    <Typography variant='body1' component='span' color='text.primary'>{series.competitorDisplayWithRecord()}</Typography>
                    <Typography variant='body2' component='span' color='text.secondary'>{series.createdAt}</Typography>
                </Box>
            }
        />
    </ListItem>
);

const SeriesListWithEdit = ({ loading, items, handleEdit }) => {
    return (
        <List>
            {loading
                ? <CircularProgress />
                : items?.map(item =>
                    <SeriesRow
                        key={item._id}
                        series={item}
                        handleEdit={handleEdit}
                    />
                )
            }
        </List>
    );
};

const SeriesList = () => {
    const [newSeries, setNewSeries] = useState(false);
    const [editSeries, setEditSeries] = useState(null);
    const [confirm, setConfirm] = useState(null);
    const { loading, error, series } = useSeriesBasic();
    const { update, remove } = useEditSeries();
    const { create } = useSeriesCreate();
    const errorOnly = useSpadesSnackbar();
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    if (error) return <ErrorPanel error={error} />;

    return (
        <>
            <AsyncHelmet text='Series' />
            {hasPermission(Permission.Write) &&
                <Box sx={{ mt: 2 }}>
                    <Button variant='contained' startIcon={<AddIcon />} onClick={() => setNewSeries(true)}>New Series</Button>
                </Box>
            }
            <SeriesListWithEdit
                loading={loading}
                items={series}
                handleEdit={hasPermission(Permission.Write) ? series => setEditSeries(series) : null}
            />
            {newSeries &&
                <NewSeriesDialog
                    handleSave={(data) => create(data, { onCompleted: data => navigate(routes.series.byId(data._id)), onError: errorOnly.onError })}
                    handleCloseDialog={() => setNewSeries(false)}
                />
            }
            {editSeries &&
                <EditSeriesDialog
                    entity={editSeries}
                    handleSave={(id, data) => update(id, data, errorOnly)}
                    handleDelete={() => setConfirm(editSeries)}
                    handleCloseDialog={() => setEditSeries(null)}
                />
            }
            {confirm &&
                <ConfirmationDialog
                    message={<Typography>Delete <strong>{confirm.name}</strong> series?</Typography>}
                    handleConfirm={() => remove(confirm._id, errorOnly)}
                    handleCloseDialog={() => setConfirm(null)}
                />
            }
        </>
    );
};

export default SeriesList;