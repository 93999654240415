export class ErrorReader {

    constructor(error) {
        this.error = error;
    }

    extensions(index = 0) {
        return this.error.graphQLErrors[index]?.extensions;
    }
    message(index = 0) {
        return this.error.graphQLErrors[index]?.message;
    }
    code(index = 0) {
        return this.extensions(index)?.code;
    }
}