import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

import { routes } from '../routes.mjs';

const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    return navigate(routes.logout);
  };

  return <Button color='inherit' variant='text' size='small' onClick={handleLogout}>Logout</Button>;
};

export default LogoutButton;