import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import AsyncHelmet from '../components/AsyncHelmet';
import { useSpadesSnackbar } from '../components/useSpadesSnackbar';
import BasicTabs from '../components/BasicTabs';
import ListWithEdit from '../components/ListWithEdit';
import EditEntityNameDialog from '../components/EditEntityNameDialog';
import NewTeamDialog from './NewTeamDialog';
import { useCompetitors, useEditCompetitor, useCreatePlayer, useCreateTeam } from './useCompetitors';
import ErrorPanel from '../components/ErrorPanel';
import { routes } from '../routes.mjs';
import { useAuth, Permission } from '../auth/useAuth';

const transform = c => ({ primary: c.name, secondary: c.players?.map(p => p.name).join(', '), ...c })

const PlayerList = ({ players, ...rest }) => {
    const [playerDialog, setPlayerDialog] = useState(null);
    const { update, remove } = useEditCompetitor();
    const { create } = useCreatePlayer();
    const errorOnly = useSpadesSnackbar();
    const { hasPermission } = useAuth();

    return (
        <Box sx={{ mt: 2 }}>
            {hasPermission(Permission.Write) &&
                <Button variant='contained' startIcon={<AddIcon />} onClick={() => setPlayerDialog({})}>New Player</Button>
            }
            <ListWithEdit
                items={players}
                displayTransform={transform}
                handleEdit={hasPermission(Permission.Write) ? p => setPlayerDialog(p) : null}
                generateRoute={c => routes.competitors.competitorStats(c._id)}
                {...rest}
            />
            {playerDialog &&
                <EditEntityNameDialog
                    entity={playerDialog}
                    handleSave={(id, data) => id ? update(id, data.name, errorOnly) : create(data.name, errorOnly)}
                    handleDelete={id => remove(id, errorOnly)}
                    handleCloseDialog={() => setPlayerDialog(null)}
                    title={playerDialog?._id ? 'Edit player' : 'New player'}
                    nameLabel='Player name'
                />
            }
        </Box>
    );
};
const TeamList = ({ teams, ...rest }) => {
    const [editTeamDialog, setEditTeamDialog] = useState(null);
    const [newTeamDialog, setNewTeamDialog] = useState(false);

    const { update, remove } = useEditCompetitor();
    const errorOnly = useSpadesSnackbar();
    const { create } = useCreateTeam();
    const { hasPermission } = useAuth();

    return (
        <Box sx={{ mt: 2 }}>
            {hasPermission(Permission.Write) &&
                <Button variant='contained' startIcon={<AddIcon />} onClick={() => setNewTeamDialog(true)}>New Team</Button>
            }
            <ListWithEdit
                items={teams}
                displayTransform={transform}
                handleEdit={hasPermission(Permission.Write) ? t => setEditTeamDialog(t) : null}
                generateRoute={c => routes.competitors.competitorStats(c._id)}
                {...rest}
            />
            {editTeamDialog &&
                <EditEntityNameDialog
                    entity={editTeamDialog}
                    handleSave={(id, data) => update(id, data.name, errorOnly)}
                    handleDelete={id => remove(id, errorOnly)}
                    handleCloseDialog={() => setEditTeamDialog(null)}
                    title='Edit team'
                    nameLabel='Team name'
                />
            }
            {newTeamDialog &&
                <NewTeamDialog
                    handleCloseDialog={() => setNewTeamDialog(false)}
                    handleSave={(_, data) => create(data.name, [data.player1, data.player2], errorOnly)}
                />
            }
        </Box>
    );
};

const TABS = [
    { slug: 'teams', title: 'Teams', component: TeamList },
    { slug: 'players', title: 'Players', component: PlayerList }
];

const CompetitorList = () => {
    const data = useCompetitors();

    if (data.error) return <ErrorPanel error={data.error} />;

    return (
        <>
            <AsyncHelmet text='Competitors' />
            <BasicTabs tabs={TABS} panelData={data} variant='fullWidth' />
        </>
    )
};

export default CompetitorList;