import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';

import BasicSelect from '../components/BasicSelect';
import { useCompetitors } from './useCompetitors';

const NewTeamDialog = ({ handleSave, handleCloseDialog }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { players } = useCompetitors();

    const { control, handleSubmit, formState: { isValid } } = useForm({
        defaultValues: {
            name: '',
            player1: '',
            player2: ''
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return handleSave(null, data);
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>New team</DialogTitle>
                <DialogContent>
                    <Box sx={{ my: 1 }} display='flex' flexDirection='column' gap={2}>
                        <Controller name='name' control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField {...field}
                                    name={`com.spadeschamp.Team_name}`}
                                    required
                                    fullWidth={true}
                                    label='Team name'
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />
                        <Controller name='player1' control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={players ?? []}
                                    label='Player 1'
                                />
                            )}
                        />
                        <Controller name='player2' control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={players ?? []}
                                    label='Player 2'
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <Box display='flex' justifyContent='flex-end' sx={{ mx: 2, mb: 1 }}>
                    <Box display='flex' gap={1}>
                        <Button
                            variant='contained'
                            type='submit'
                            disabled={!isValid}
                        >Save</Button>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                    </Box>
                </Box>
            </form>
        </Dialog>
    );
};

export default NewTeamDialog;