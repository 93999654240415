export const CompetitorTypeEnum = Object.freeze({
    PLAYER: 'player',
    TEAM: 'team'
});
export const SeriesTypeEnum = Object.freeze({
    THREE_PLAYER: '3player',
    FOUR_PLAYER: '4player'
});
export const SeriesStatusEnum = Object.freeze({
    ACTIVE: 'active',
    COMPLETE: 'complete',
    ARCHIVED: 'archived'
});
export const GameStatusEnum = Object.freeze({
    IN_PROGRESS: 'inprogress',
    COMPLETE: 'complete'
});
export const GameCompletedReasonEnum = Object.freeze({
    WINNER: 'winner', // winner is automatically assigned
    FORFEIT: 'forfeit', // one competitor forfeits
    ABANDONED: 'abandoned', // game is not continued
    ASSIGNED: 'assigned' // winner is manually assigned (usually when last hand is not scored)
});
export const HandStatusEnum = Object.freeze({
    IN_PROGRESS: 'inprogress',
    COMPLETE: 'complete'
});
export const ScoreFlagEnum = Object.freeze({
    TEN_BID_BONUS: 'ten-bid-bonus',
    NIL_BID_BONUS: 'nil-bid-bonus',
    SANDBAG_PENALTY: 'sandbag-penalty',
    NIL_BID_PENALTY: 'nil-bid-penalty',
    OVERBID_PENALTY: 'overbid-penalty'
});
export const NilBidType = Object.freeze({
    INDIVIDUAL: 'individual'
});
export const InvalidScoreReasonEnum = Object.freeze({
    HAND_COMPLETED: 'HAND_COMPLETED',
    INCALCULABLE_SCORE: 'INCALCULABLE_SCORE',
    INCORRECT_ACTUAL_TOTAL: 'INCORRECT_ACTUAL_TOTAL'
});