import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import BackHandIcon from '@mui/icons-material/BackHand';
import RefreshIcon from '@mui/icons-material/Refresh';

import AsyncHelmet from '../components/AsyncHelmet';
import { useSpadesSnackbar } from '../components/useSpadesSnackbar';
import ErrorPanel from '../components/ErrorPanel';
import { useNavBar } from '../components/NavBar';
import { routes } from '../routes.mjs';
import { useSeriesById, useAutoRefresh } from './useSeries';
import { useComplete, useScoreParameters, useSetGameOptions, useEditDealers, useSetScoreOverride, useEditLastHand } from './useScore';
import NumberPad from './NumberPad';
import { NilBidType } from '../constants';
import { ScoreDisplay } from './ScoreboardComponents';
import EditOptionsDialog from './EditOptionsDialog';
import EditDealersDialog from './EditDealersDialog';
import EditScoreDialog from './EditScoreDialog';
import EditGameDialog from './EditGameDialog';
import { HandSection } from './SeriesHistory';

const ScoreParamEnum = Object.freeze({
    Bid: 'bid',
    Actual: 'actual'
});

const KeepScore = () => {
    const params = useParams();
    const seriesId = params.id;

    const { navItems, setNavItems } = useNavBar();
    const autoRefreshState = useAutoRefresh(false);
    const { series, error, refetch, startPolling, stopPolling } = useSeriesById(seriesId, true);

    useEffect(() => {
        if (!series || navItems.title === series.name) return;
        setNavItems({ title: series.name, to: routes.series.byId(seriesId) })
    }, [seriesId, series, navItems, setNavItems]);

    useEffect(() => {
        autoRefreshState.autoRefresh ? startPolling(1000) : stopPolling();
    }, [autoRefreshState.autoRefresh, startPolling, stopPolling])

    if (error) return <ErrorPanel error={error} />;

    return (series
        ? <Stack gap={1}>
            <AsyncHelmet text='Keep Score' />
            <GameHeader series={series} />
            <Scoreboard series={series} />
            <HandHeader series={series} refetch={refetch} autoRefreshState={autoRefreshState} />
            <ScoreTable series={series} />
            <CompletionButton series={series} />
            <ScoreHistory series={series} />
        </Stack>
        : null
    );
};
export default KeepScore;

const CannotCompleteMessage = ({ hand }) => (
    hand.cannotCompleteReason === 'INCORRECT_ACTUAL_TOTAL'
        ? <Typography align='center' color='error'>{`Actual books (${hand.cannotCompleteArgs.actual}) must equal ${hand.cannotCompleteArgs.available}.`}</Typography>
        : null
);

const CompletionButton = ({ series }) => {
    const errorOnly = useSpadesSnackbar();
    const { completeHand, completeGame } = useComplete();

    return (series.lastGame.canComplete
        ? <Button variant='contained' color='error'
            onClick={() => completeGame(series._id, null, errorOnly)}
        >Complete Game</Button>
        : (series.lastHand.canComplete
            ? <Button variant='contained' color='primary'
                onClick={() => completeHand(series._id, errorOnly)}
            >Complete Hand</Button>
            : <CannotCompleteMessage hand={series.lastHand} />
        )
    );
};

const ScoreHistory = ({ series }) => {
    const { editLastHand } = useEditLastHand();

    const canEditLastHand = series.lastHand.handNumber > 1 && series.lastHand.isEmpty();
    return (
        <>
            <Typography variant='h6'>Score History</Typography>
            {canEditLastHand &&
                <Box><Button variant='outlined' size='small' onClick={() => editLastHand(series._id)}>Edit Last Hand</Button></Box>
            }
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableBody>
                        <HandSection series={series} game={series.lastGame} order={-1} />
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

const GameHeader = ({ series }) => {
    const { setGameOptions } = useSetGameOptions();
    const { completeGame } = useComplete();
    const [showOptionsDialog, setShowOptionsDialog] = useState(false);
    const [showEditGameDialog, setShowEditGameDialog] = useState(false);

    return (
        <Box display='flex' flexDirection='column'>
            <Box display='flex' alignItems='baseline'>
                <Typography variant='h6'>Game {series.gameNumber}</Typography>
                <Button size='small' onClick={() => setShowEditGameDialog(true)}>End</Button>
            </Box>
            <Box display='flex' alignItems='center'>
                <Typography>{series.optionsDisplay()}</Typography>
                <IconButton size='small' onClick={() => setShowOptionsDialog(true)}>
                    <EditIcon fontSize='inherit' />
                </IconButton>
            </Box>
            {showOptionsDialog &&
                <EditOptionsDialog
                    game={series.lastGame}
                    handleSave={opts => setGameOptions(series._id, opts)}
                    handleCloseDialog={() => setShowOptionsDialog(false)}
                />
            }
            {showEditGameDialog &&
                <EditGameDialog
                    series={series}
                    handleSave={(seriesId, data) => completeGame(seriesId, data)}
                    handleCloseDialog={() => setShowEditGameDialog(false)}
                />
            }
        </Box>
    );
};
const HandHeader = ({ series, refetch, autoRefreshState }) => {
    const { setGameOptions } = useSetGameOptions();
    const { setDealer } = useEditDealers();
    const [showDealerDialog, setShowDealerDialog] = useState(false);

    const handleSave = (data) => {
        // current dealer - only update if it has changed
        const setDealerResult = data.dealer !== series.dealerId ? setDealer(series._id, data.dealer) : null;

        // dealing order - only update if it has changed
        const currentOrder = series.dealers.map(d => d._id);
        const newOrder = data.dealers.map(d => d._id);
        const setDealingOrderResult = JSON.stringify(currentOrder) !== JSON.stringify(newOrder) ? setGameOptions(series._id, { dealingOrder: newOrder }) : null;

        return Promise.all([setDealerResult, setDealingOrderResult]);
    };
    const handleAutoRefreshToggle = (e) => autoRefreshState.setAutoRefresh(e.target.checked);

    return (
        <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex' flexDirection='column'>
                <Typography>Hand {series.handNumber}</Typography>
                <FormControlLabel label={<Typography variant='caption'>Auto-refresh</Typography>} control={
                    <Switch size='small' checked={autoRefreshState.autoRefresh} onChange={handleAutoRefreshToggle} />
                } />
            </Box>
            {!autoRefreshState.autoRefresh &&
                <IconButton onClick={() => refetch()}>
                    <RefreshIcon fontSize='inherit' />
                </IconButton>
            }
            <Chip
                color='primary'
                variant='outlined'
                label={<Typography variant='body2'>{series.dealerName}</Typography>}
                icon={<BackHandIcon fontSize='small' />}
                onClick={() => setShowDealerDialog(true)}
            />
            {showDealerDialog &&
                <EditDealersDialog
                    series={series}
                    handleSave={handleSave}
                    handleCloseDialog={() => setShowDealerDialog(false)}
                />
            }
        </Box>
    );
};

const BooksRemaining = ({ hand }) => (
    <Box display='flex'>
        <Typography variant='body2'>Remaining books: {hand.remainingBooks}</Typography>
    </Box>
);

const numberPadTitle = (scoredHand, field) => `Select ${field} for ${scoredHand.competitorName}`;

const ScoreTable = ({ series }) => {
    const [selected, setSelected] = useState(null);
    const [editScoredHand, setEditScoredHand] = useState(null);
    const { setScoreParameters } = useScoreParameters();
    const { setScoreOverride } = useSetScoreOverride();

    const numberPadHandler = (val) => {
        // val is null when selecting the X close button
        if (val !== null) {
            const field = selected.field;
            const input = {};

            if (val > 0) {
                // set or unset bid/actual
                input[field] = selected.scoredHand[field] === val ? null : val;
            } else if (selected.field === ScoreParamEnum.Bid) {
                input.nilBidType = selected.scoredHand.nilBid ? null : NilBidType.INDIVIDUAL;
            } else if (selected.field === ScoreParamEnum.Actual) {
                input.nilBidTypeActual = selected.scoredHand.nilBidActual ? null : NilBidType.INDIVIDUAL;
            }
            setScoreParameters(series._id, selected.scoredHand.competitorId, input);
        }
        setSelected(null);
    };

    const config = (scoredHand, field) => {
        const selectedWrapper = (scoredHand, field) => {
            return () => setSelected(
                prev => prev?.scoredHand._id === scoredHand._id && prev?.field === field
                    ? null
                    : ({ scoredHand, field })
            );
        };
        const variantForSelected = (scoredHand, field) => {
            return selected?.scoredHand._id === scoredHand._id && selected?.field === field
                ? 'outlined'
                : 'text';
        };

        return {
            onClick: selectedWrapper(scoredHand, field),
            variant: variantForSelected(scoredHand, field)
        }
    };

    const sx = { minWidth: 0 };
    const scoredHands = series.competitorsByDealingOrder().map(c => series.scoredHandForCompetitor(c._id));
    return (
        <Box display='flex' flexDirection='column' gap={1}>
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align='center'>
                                <Typography variant='body2' fontWeight='bold'>Bid</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography variant='body2' fontWeight='bold'>Actual</Typography>
                            </TableCell>
                            <TableCell align='center'>
                                <Typography variant='body2' fontWeight='bold'>Score</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {scoredHands.map(sh => (
                            <TableRow key={sh._id}>
                                <TableCell>{sh.competitorName}</TableCell>
                                <TableCell align='center'>
                                    <Button size='small' sx={sx} {...config(sh, ScoreParamEnum.Bid)}>
                                        {sh.bidDisplay ?? '--'}
                                    </Button>
                                </TableCell>
                                <TableCell align='center'>
                                    <Button size='small' sx={sx} {...config(sh, ScoreParamEnum.Actual)}>
                                        {sh.actualDisplay ?? '--'}
                                    </Button>
                                </TableCell>
                                <TableCell align='center'>
                                    <Button size='small' sx={sx} onClick={() => setEditScoredHand(sh)}>
                                        {series.activeScoreForCompetitor(sh.competitorId)?.combined()}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {editScoredHand &&
                <EditScoreDialog
                    series={series}
                    scoredHand={editScoredHand}
                    handleSave={(seriesId, competitorId, input) => setScoreOverride(seriesId, competitorId, input)}
                    handleCloseDialog={() => setEditScoredHand(false)}
                />
            }
            <BooksRemaining hand={series.lastHand} />
            <Collapse in={!!selected} timeout='auto'>
                {selected &&
                    <NumberPad
                        title={numberPadTitle(selected.scoredHand, selected.field)}
                        selected={selected.scoredHand.fieldAsArray(selected.field)}
                        showNil={series.hasNilBonus()}
                        availableBooks={series.availableBooks}
                        handler={numberPadHandler}
                    />
                }
            </Collapse>
        </Box>
    )
};

const ScoreBox = ({ activeScore, winner }) => (
    <Paper sx={{ p: 1 }}>
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Typography variant='h5' fontWeight='bold'>{activeScore.competitorName}</Typography>
            <ScoreDisplay activeScore={activeScore} winner={winner} />
        </Box>
    </Paper>
);
const Scoreboard = ({ series }) => {
    return (
        <Box display='flex' gap={1} alignItems='center'>
            {series.lastGame.activeScores?.map((activeScore, idx) => (
                <Box key={idx} flexGrow={1} flexBasis={0}>
                    <ScoreBox
                        activeScore={activeScore}
                        winner={series.lastGame.winnerId === activeScore.competitorId}
                    />
                </Box>
            ))}
        </Box>
    );
};