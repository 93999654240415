import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';

import BasicSelect from '../components/BasicSelect';
import { GameCompletedReasonEnum } from '../constants';

const ReasonSelection = Object.freeze([
    { _id: GameCompletedReasonEnum.WINNER, name: 'Automatic' },
    { _id: GameCompletedReasonEnum.ASSIGNED, name: 'Assign winner' },
    { _id: GameCompletedReasonEnum.FORFEIT, name: 'Forfeit' },
    { _id: GameCompletedReasonEnum.ABANDONED, name: 'Abandon' },
]);
const requiresWinnerSelection = reason => [GameCompletedReasonEnum.ASSIGNED, GameCompletedReasonEnum.FORFEIT].includes(reason);

const EditGameDialog = ({ series, handleSave, handleCloseDialog }) => {
    const formControls = useForm({
        defaultValues: {
            completedReason: series.lastGame.completedReason ?? GameCompletedReasonEnum.WINNER,
            winningCompetitorId: series.lastGame.winnerId ?? ''
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return data.completedReason !== GameCompletedReasonEnum.WINNER ? handleSave(series._id, data) : null;
    };

    return (
        <GameDialog
            title='Edit game'
            onSave={onSave}
            competitors={series.competitors}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        />
    );
};

export default EditGameDialog;

const GameDialog = ({ title, competitors, onSave, handleCloseDialog, formControls }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { control, handleSubmit, watch, formState: { isValid } } = formControls;

    const reason = watch('completedReason');
    const showWinnerSelection = requiresWinnerSelection(reason);
    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ my: 1 }} display='flex' flexDirection='column' gap={2}>
                        <Controller name='completedReason' control={control}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={ReasonSelection}
                                    label='Select winner'
                                />
                            )}
                        />
                        {showWinnerSelection &&
                            <Controller name='winningCompetitorId' control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <BasicSelect {...field}
                                        items={competitors}
                                        label='Winner'
                                    />
                                )}
                            />
                        }
                    </Box>
                </DialogContent>
                <Box display='flex' justifyContent='flex-end' gap={1} sx={{ m: 1 }}>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid}
                    >Save</Button>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                </Box>
            </form>
        </Dialog>
    );
};
