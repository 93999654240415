// Important: these routes must match what is defined in router.js
export const routes = {
    root: '/',
    logout: `/logout`,
    login: `/login`,
    competitors: {
        view: (tab) => `/competitors${tab ? `?tab=${tab}` : ''}`,
        competitorStats: id => `/competitors/${id}/stats`,
    },
    series: {
        view: `/series`,
        byId: id => `/series/${id}`,
        history: id => `/series/${id}/history`,
        stats: (id, competitorId) => `/series/${id}/stats${competitorId ? `?tab=${competitorId}` : ''}`,
    },
    fullscreen: {
        play: seriesId => `/fs/series/${seriesId}/play`,
        scoreboard: seriesId => `/fs/series/${seriesId}/scoreboard`
    }
};