import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

const PageContent = () => {
    return (
        <Container sx={{ py: 2 }}>
            <Outlet />
        </Container>
    );
};

export default PageContent;