import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { ErrorReader } from '../error';

const ErrorPanel = ({ error }) => {
    console.error('error', error);

    const rdr = new ErrorReader(error);
    return (
        <Container sx={{ py: 2 }}>
            <Typography variant='body1' color='error.main'>An unknown error has occurred :(</Typography>
            <Typography variant='body2' color='error.main'>{rdr.message()}</Typography>
        </Container>
    );
};

export default ErrorPanel;