import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';

import BasicSelect from '../components/BasicSelect';

const range = (start, end, incr = 1) => {
    const items = new Array((end - start) / incr + 1);
    for (let idx = 0; idx < items.length; idx++) {
        items[idx] = start + idx * incr;
    }
    return items;
};

const SCORE_LIMIT = range(100, 500, 50).map(n => ({ _id: n, name: n }));
const BID_BONUS = range(0, 250, 50).map(n => ({ _id: n, name: n }));
const SANDBAG_LIMIT = range(1, 9).map(n => ({ _id: n, name: n }));

const EditOptionsDialog = ({ game, handleSave, handleCloseDialog }) => {
    const formControls = useForm({
        defaultValues: {
            scoreLimit: game.options.scoreLimit,
            nilBidBonus: game.options.nilBidBonus,
            tenBidBonus: game.options.tenBidBonus,
            sandbagPenaltyLimit: game.options.sandbagPenaltyLimit
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return handleSave(data);
    };

    return (
        <OptionsDialog
            title={`Game ${game.gameNumber} Options`}
            onSave={onSave}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        />
    );
};

export default EditOptionsDialog;

const OptionsDialog = ({ title, onSave, handleCloseDialog, formControls }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const { control, handleSubmit, formState: { isValid } } = formControls;
    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ my: 1 }} display='flex' flexDirection='column' gap={2}>
                        <Controller name='scoreLimit' control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={SCORE_LIMIT}
                                    label='Score limit'
                                />
                            )}
                        />
                        <Controller name='nilBidBonus' control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={BID_BONUS}
                                    label='Nil bid bonus'
                                />
                            )}
                        />
                        <Controller name='tenBidBonus' control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={BID_BONUS}
                                    label='Ten bid bonus'
                                />
                            )}
                        />
                        <Controller name='sandbagPenaltyLimit' control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <BasicSelect {...field}
                                    items={SANDBAG_LIMIT}
                                    label='Sandbag penalty limit'
                                />
                            )}
                        />
                    </Box>
                </DialogContent>
                <Box display='flex' justifyContent='flex-end' gap={1} sx={{ m: 1 }}>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid}
                    >Save</Button>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                </Box>
            </form>
        </Dialog>
    );
};
