import {
    createBrowserRouter
} from 'react-router-dom';

import Root from './components/Root';
import Home from './home/Home';
import { ProtectedComponent } from './auth/Auth0Components';
import LoginRedirect from './auth/LoginRedirect';
import LogoutLanding from './auth/LogoutLanding';
import PageContent from './components/PageContent';
import CompetitorList from './competitors/CompetitorList';
import SeriesList from './series/SeriesList';
import SeriesSummary from './series/SeriesSummary';
import SeriesHistory from './series/SeriesHistory';
import CompetitorStatistics from './competitors/CompetitorStatistics';
import SeriesStatistics from './series/SeriesStatistics';
import Scoreboard from './series/Scoreboard';
import KeepScore from './series/KeepScore';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            { path: '', exact: true, element: <Home /> },
            { path: 'login', exact: true, element: <LoginRedirect /> },
            { path: 'logout', exact: true, element: <LogoutLanding /> },
            {
                path: 'competitors',
                element: <ProtectedComponent component={PageContent} />,
                children: [
                    { path: '', exact: true, element: <CompetitorList /> },
                    { path: ':id/stats', exact: true, element: <CompetitorStatistics /> }
                ]
            },
            {
                path: 'series',
                element: <ProtectedComponent component={PageContent} />,
                children: [
                    { path: '', exact: true, element: <SeriesList /> },
                    { path: ':id', exact: true, element: <SeriesSummary /> },
                    { path: ':id/history', exact: true, element: <SeriesHistory /> },
                    { path: ':id/stats', exact: true, element: <SeriesStatistics /> },
                ]
            },
            {
                path: 'fs/series',
                element: <ProtectedComponent component={PageContent} />,
                children: [
                    { path: ':id/scoreboard', exact: true, element: <Scoreboard /> },
                    { path: ':id/play', exact: true, element: <KeepScore /> }
                ]
            }
        ]
    }
]);