import { useForm, Controller } from 'react-hook-form';
import { EditDialog } from '../components/EditEntityNameDialog';
import BasicSelect from '../components/BasicSelect';
import { SeriesStatusEnum } from '../constants';

const SeriesStatusSelector = [
    { _id: SeriesStatusEnum.ACTIVE, name: 'Active' },
    { _id: SeriesStatusEnum.COMPLETE, name: 'Completed' },
];

const EditSeriesDialog = ({ entity, handleSave, handleDelete, handleCloseDialog }) => {
    const formControls = useForm({
        defaultValues: {
            name: entity?.name ?? '',
            status: entity?.status ?? ''
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return handleSave(entity._id, data);
    };
    const onDelete = () => {
        handleCloseDialog();
        return handleDelete(entity._id);
    };

    const { control } = formControls;
    const canDelete = !!entity?._id;
    return (
        <EditDialog
            title='Edit series'
            nameLabel='Series name'
            canDelete={canDelete}
            onDelete={onDelete}
            onSave={onSave}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        >
            <Controller name='status' control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <BasicSelect {...field}
                        items={SeriesStatusSelector}
                        label='Status'
                    />
                )}
            />
        </EditDialog>
    );
};

export default EditSeriesDialog;
