import { useForm, Controller } from 'react-hook-form';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { EditDialog } from '../components/EditEntityNameDialog';
import BasicSelect from '../components/BasicSelect';
import { SeriesTypeEnum } from '../constants';
import { useCompetitors } from '../competitors/useCompetitors';
import GenericLoading from '../components/GenericLoading';

const SeriesTypeToggle = ({ value, onChange }) => (
    <ToggleButtonGroup value={value} exclusive onChange={onChange} color='primary' size='small' fullWidth>
        <ToggleButton value={SeriesTypeEnum.FOUR_PLAYER}>
            Four Player
        </ToggleButton>
        <ToggleButton value={SeriesTypeEnum.THREE_PLAYER}>
            Three Player
        </ToggleButton>
    </ToggleButtonGroup>
);

const NewSeriesDialog = ({ handleSave, handleCloseDialog }) => {
    const { teams, players, loading } = useCompetitors();
    const formControls = useForm({
        defaultValues: {
            name: '',
            type: SeriesTypeEnum.FOUR_PLAYER,
            competitor1: '',
            competitor2: '',
            competitor3: ''
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return handleSave({
            name: data.name,
            type: data.type,
            competitors: data.type === SeriesTypeEnum.THREE_PLAYER
                ? [data.competitor1, data.competitor2, data.competitor3]
                : [data.competitor1, data.competitor2]
        });
    };

    const { control, watch } = formControls;

    const type = watch('type');
    const items = type === SeriesTypeEnum.FOUR_PLAYER ? teams : players;
    return (loading
        ? <GenericLoading />
        : <EditDialog
            title='New series'
            nameLabel='Series name'
            canDelete={false}
            onSave={onSave}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        >
            <Controller name='type' control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <SeriesTypeToggle value={field.value} onChange={field.onChange} />
                )}
            />
            <Controller name='competitor1' control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <BasicSelect {...field}
                        items={items ?? []}
                        label='Competitor 1'
                    />
                )}
            />
            <Controller name='competitor2' control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <BasicSelect {...field}
                        items={items ?? []}
                        label='Competitor 2'
                    />
                )}
            />
            {type === SeriesTypeEnum.THREE_PLAYER &&
                <Controller name='competitor3' control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <BasicSelect {...field}
                            items={items ?? []}
                            label='Competitor 3'
                        />
                    )}
                />
            }
        </EditDialog>
    );
};

export default NewSeriesDialog;
