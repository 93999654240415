import { useState } from 'react';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const COLUMNS = 5;

const range = (start, end) => {
    const items = new Array(end - start + 1);
    for (let val = start; val <= end; val++) {
        items[val - start] = { value: val, display: val === 0 ? 'nil' : `${val}` };
    }
    return items;
};

const NumberPad = ({ title, selected, showNil, availableBooks, handler }) => {
    const [displayMore, setDisplayMore] = useState(false);

    const gridRows = !displayMore ? 2 : Math.ceil(availableBooks / COLUMNS);
    const buttons = range(showNil ? 0 : 1, Math.min(availableBooks, COLUMNS * gridRows - (showNil ? 1 : 0)));

    const grid = {
        display: 'grid',
        gridTemplateRows: `repeat(${gridRows}, auto)`,
        gridTemplateColumns: `repeat(${COLUMNS}, 1fr)`,
        columnGap: `5px`,
        rowGap: `5px`
    };

    return (
        <Box display='flex' flexDirection='column' >
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='body2' fontStyle='italic'>{title}</Typography>
                <IconButton size='small' onClick={() => handler(null)}><CloseIcon fontSize='inherit' /></IconButton>
            </Box>
            <Box sx={grid}>
                {buttons.map(b => (
                    <ToggleButton
                        key={b.value}
                        value={b.value}
                        selected={selected.includes(b.value)}
                        onClick={(_, val) => handler(val)}
                    >
                        {b.display}
                    </ToggleButton>
                ))}
            </Box>
            {!displayMore &&
                <Button onClick={() => setDisplayMore(val => !val)}>More</Button>
            }
        </Box>
    );
};

export default NumberPad;