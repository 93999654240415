import dayjs from 'dayjs';
import { HandStatusEnum, SeriesTypeEnum } from '../constants';

class CompetitorModel {
    constructor(competitor) {
        this._competitor = competitor;
    }

    get _id() {
        return this._competitor._id;
    }
    get name() {
        return this._competitor.name;
    }

    hasPlayer(playerId) {
        return this._competitor.players?.findIndex(p => p._id === playerId) >= 0 ?? -1;
    }
}

class ActiveScore {
    constructor(activeScore) {
        this._activeScore = activeScore;
        this._competitor = new CompetitorModel(activeScore.competitor);
    }

    get competitor() {
        return this._competitor;
    }
    get competitorId() {
        return this._competitor._id;
    }
    get competitorName() {
        return this._competitor.name;
    }

    get currentScore() {
        return this._activeScore.currentScore;
    }
    get proposedScore() {
        return this._activeScore.proposedScore;
    }
    get bidDisplay() {
        return this._activeScore.bidDisplay;
    }
    get actualDisplay() {
        return this._activeScore.actualDisplay;
    }

    combined() {
        return [this.currentScore, this.proposedScore].filter(s => !!s).join(' → ');
    }
}

class ScoredHandModel {
    constructor(scoredHand) {
        this._scoredHand = scoredHand;
        this._competitor = new CompetitorModel(scoredHand.competitor);
    }

    get _id() {
        return this._scoredHand._id;
    }
    get scoreDisplay() {
        return this._scoredHand.scoreDisplay;
    }
    get bid() {
        return this._scoredHand.bid;
    }
    get bidDisplay() {
        return this._scoredHand.bidDisplay;
    }
    get actual() {
        return this._scoredHand.actual;
    }
    get actualDisplay() {
        return this._scoredHand.actualDisplay;
    }
    get flags() {
        return this._scoredHand.flags;
    }
    get competitorId() {
        return this._competitor._id;
    }
    get competitorName() {
        return this._competitor.name;
    }
    get nilBid() {
        return this._scoredHand.nilBidType;
    }
    get nilBidActual() {
        return this._scoredHand.nilBidTypeActual;
    }
    get scoreOverride() {
        return this._scoredHand.scoreOverride;
    }
    get score() {
        return this._scoredHand.score;
    }
    get sandbag() {
        return this._scoredHand.sandbag;
    }

    isEmpty() {
        return !(this._scoredHand.bid ?? this._scoredHand.actual ?? this._scoredHand.nilBidType ?? this._scoredHand.nilBidTypeActual);
    }
    hasFlags() {
        return this._scoredHand.flags?.length > 0 ?? false;
    }
    hasFlag(flag) {
        return this._scoredHand.flags?.includes(flag) ?? false;
    }
    isNegativeScore() {
        return this.scoreDisplay?.startsWith('-') ?? false;
    }
    bidActualDisplay(empty = '-') {
        return `${this._scoredHand.bidDisplay ?? empty} / ${this._scoredHand.actualDisplay ?? empty}`;
    }
    bidArray() {
        return [this._scoredHand.bid, this.nilBid ? 0 : null].filter(i => i >= 0);
    }
    actualArray() {
        return [this._scoredHand.actual, this.nilBidActual ? 0 : null].filter(i => i >= 0);
    }
    fieldAsArray(field) {
        switch (field) {
            case 'bid':
                return this.bidArray();
            case 'actual':
                return this.actualArray();
            default:
                return null;
        }
    }
}

class HandModel {
    constructor(hand) {
        this._hand = hand;
        this._scoredHands = hand.scoredHands?.map(sh => new ScoredHandModel(sh));
        this._scoredHands?.sort((a, b) => a.competitorName.localeCompare(b.competitorName));
        this._dealer = new CompetitorModel(hand.dealer);
    }

    get _id() {
        return this._hand._id;
    }
    get handNumber() {
        return this._hand.handNumber;
    }
    get remainingBooks() {
        return this._hand.remainingBooks;
    }

    get scoredHands() {
        return this._scoredHands;
    }
    get endedAt() {
        return this._hand.endedAt;
    }
    get dealerId() {
        return this._dealer._id;
    }
    get dealerName() {
        return this._dealer.name;
    }
    get status() {
        return this._hand.status;
    }
    get canComplete() {
        return this._hand.completionStatus.canComplete;
    }
    get cannotCompleteReason() {
        return this._hand.completionStatus.invalidReason;
    }
    get cannotCompleteArgs() {
        return this._hand.completionStatus.args;
    }

    isEmpty() {
        return this._scoredHands.every(sh => sh.isEmpty());
    }
    endedAtDisplay() {
        return this.endedAt ? dayjs(this.endedAt).format('h:mm a') : null;
    }
    footer() {
        return [this.endedAtDisplay(), this.dealerName].filter(i => !!i).join(' / ');
    }
    scoredHandForCompetitor(competitorId) {
        return this._scoredHands.find(sh => sh.competitorId === competitorId);
    }
}

class GameModel {
    constructor(game) {
        this._game = game;
        this._hands = game.hands?.map(h => new HandModel(h));
        this._winner = game.winner ? new CompetitorModel(game.winner) : null;
        this._activeScores = game.activeScores?.map(a => new ActiveScore(a));
        this._activeScores?.sort((a, b) => a.competitorName.localeCompare(b.competitorName));
        this._dealers = game.options.dealers.map(d => new CompetitorModel(d));
    }

    get _id() {
        return this._game._id;
    }
    get gameNumber() {
        return this._game.gameNumber;
    }
    get hands() {
        return this._hands;
    }
    get winnerId() {
        return this._winner?._id;
    }
    get winnerName() {
        return this._winner?.name;
    }
    get lastHand() {
        return this._hands.at(-1);
    }
    get activeScores() {
        return this._activeScores;
    }
    get canComplete() {
        return this._game.canComplete;
    }
    get options() {
        return this._game.options;
    }
    get dealers() {
        return this._dealers;
    }
    get completedReason() {
        return this._game.completedReason;
    }

    reverseHands(completed = true) {
        return this._hands
            .filter(h => !completed || h.status === HandStatusEnum.COMPLETE)
            .reverse();
    }
    hasWinner() {
        return !!this._winner;
    }
    inProgress() {
        return this._game.status === 'inprogress';
    }
    isAbandoned() {
        return this._game.completedReason === 'abandoned';
    }
    gameDisplay() {
        return `Game ${this.gameNumber}`;
    }
    updatedAtDisplay() {
        return dayjs(this._game.updatedAt).format('MMM D, YYYY, h:mm a');
    }
    optionsDisplay() {
        const display = [];
        const options = this._game.options;

        display.push(`Score ${options.scoreLimit}`);

        if (options.nilBidBonus > 0) {
            display.push('nil bid');
        }
        if (options.tenBidBonus > 0) {
            display.push('ten bid bonus');
        }
        if (options.nilBidBonus === 0 && options.tenBidBonus === 0) {
            display.push('no bonuses');
        }

        return display.join(', ');
    }
    hasNilBonus() {
        return this._game.options.nilBidBonus > 0;
    }
    activeScoreForCompetitor(competitorId) {
        return this._activeScores?.find(a => a.competitorId === competitorId);
    }
    scoredHandForCompetitor(competitorId) {
        return this.lastHand.scoredHandForCompetitor(competitorId);
    }
}

export class SeriesModel {
    constructor(series) {
        this._series = series;
        this._games = series.games?.map(g => new GameModel(g));
        this._competitors = series.competitors?.map(c => new CompetitorModel(c));
        this._competitors?.sort((a, b) => a.name.localeCompare(b.name));
    }

    get _id() {
        return this._series._id;
    }
    get name() {
        return this._series.name;
    }
    get status() {
        return this._series.status;
    }
    get type() {
        return this._series.type;
    }

    get createdAt() {
        return dayjs(this._series.createdAt).format('MMM D, YYYY');
    }
    get updatedAt() {
        return dayjs(this._series.updatedAt).format('MMM D, YYYY');
    }

    get competitors() {
        return this._competitors;
    }
    get competitorCount() {
        return this._series.competitorCount;
    }
    get availableBooks() {
        return this._series.availableBooks;
    }

    get games() {
        return this._games;
    }
    get lastGame() {
        return this._games.at(-1);
    }
    get lastHand() {
        return this.lastGame?.lastHand;
    }
    get dealerId() {
        return this.lastHand?.dealerId;
    }
    get dealerName() {
        return this.lastHand?.dealerName;
    }
    get dealers() {
        return this.lastGame.dealers;
    }

    get gameNumber() {
        return this.lastGame.gameNumber;
    }
    get handNumber() {
        return this.lastHand.handNumber;
    }

    isActive() {
        return this._series.status === 'active';
    }
    gameHandDisplay() {
        return `Game ${this.lastGame.gameNumber} / Hand ${this.lastHand.handNumber}`;
    }
    competitorDisplay() {
        return this._competitors.map(c => c.name).join(' vs ');
    }
    competitorDisplayWithRecord() {
        return this._competitors.map(c => `${c.name} (${this.winsForCompetitor(c._id)})`).join(' vs ');
    }
    optionsDisplay() {
        return this.lastGame?.optionsDisplay();
    }
    scoredHandForCompetitor(competitorId) {
        return this.lastGame.scoredHandForCompetitor(competitorId);
    }
    activeScoreForCompetitor(competitorId) {
        return this.lastGame.activeScoreForCompetitor(competitorId);
    }
    hasNilBonus() {
        return this.lastGame.hasNilBonus();
    }
    competitorsByDealingOrder() {
        const rotate = (list, idx) => list.slice(idx, list.length).concat(list.slice(0, idx));

        let list;
        let index;
        const dealerId = this.dealerId;
        if (this.type === SeriesTypeEnum.THREE_PLAYER) {
            list = this.dealers;
            index = list.findIndex(c => c._id === dealerId);
        } else if (this.type === SeriesTypeEnum.FOUR_PLAYER) {
            // this works because there is only two competitors (they are sorted alphabetically not by dealing order)
            list = this.competitors;
            index = list.findIndex(c => c.hasPlayer(dealerId));
        }
        return list ? rotate(list, (index + 1) % list.length) : null;
    }
    winsForCompetitor(competitorId) {
        for (const r of this._series.records) {
            if (r.competitor._id === competitorId) {
                return r.wins;
            }
        }
    }
}