import { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import { ErrorReader } from '../error';
import { SeriesModel } from './series.model';
import { StatisticsListModel, SummaryModel, HighlightsListModel } from '../statistics/stats.model';

export const SERIES_FRAGMENT = `
_id name createdAt updatedAt type status
availableBooks competitorCount
competitors {
    _id name
    ... on Team {
        players { _id name }
    }
}
games(active: $active) {
    _id createdAt updatedAt gameNumber status completedReason canComplete
    
    winner {
        _id name
    }
    activeScores {
        currentScore proposedScore bidDisplay actualDisplay
        competitor { _id name }
    }

    options {
        scoreLimit nilBidBonus tenBidBonus sandbagPenaltyLimit
        dealers {
            _id name
        }
    }
    
    hands {
        _id handNumber status startedAt endedAt availableBooks remainingBooks
        dealer {
            _id name
        }
        completionStatus {
            canComplete invalidReason args
        }
        scoredHands {
            _id bid actual sandbag score scoreOverride nilBidType nilBidTypeActual flags
            scoreDisplay bidDisplay actualDisplay
            competitor {
                _id name
            }
        }
    }
}
`;

const ALL_SERIES = gql`
query AllSeries($active: Boolean) {
    allSeries { ${SERIES_FRAGMENT} }
}
`;

const ALL_SERIES_BASIC = gql`
query AllSeriesBasic {
    allSeries {
        _id name status createdAt updatedAt
        competitors {
            _id name
        }
        records {
            competitor { _id }
            wins
        }
    }
}
`;

const SERIES_BY_ID = gql`
query SeriesById($id: ID!, $active: Boolean) {
    series(id: $id) { ${SERIES_FRAGMENT} }
}
`;

const SERIES_SUMMARY = gql`
query SeriesSummary($id: ID!) {

    series(id: $id) {
        _id name status
    }

    statistics(seriesId: $id) {
        wins
        series { _id name }
        competitor {
            _id name
            ... on Team {
                players { _id name }
            }
        }
    }

    summary(seriesId: $id) {
        dateStarted dateEnded gamesPlayed handsPlayed
    }
    
    highlights(seriesId: $id) {
        dateEnded dateStarted gamesPlayed handsPlayed
        records {
            wins
            competitor {
                _id name
            }
        }
    }
}
`;

const SCOREBOARD = gql`
query Scoreboard($id: ID!, $active: Boolean) {

    series(id: $id) { ${SERIES_FRAGMENT} }

    statistics(seriesId: $id) {
        wins
        competitor {
            _id name
        }
    }
    
    highlights(seriesId: $id) {
        dateEnded
        records {
            wins
            competitor {
                _id name
            }
        }
    }
}
`;

const SERIES_UPDATE = gql`
mutation SeriesUpdate($id: ID!, $input: SeriesUpdateInput!) {
    seriesUpdate(id: $id, input: $input) { 
        _id name updatedAt status
    }
}
`;
const SERIES_DELETE = gql`
mutation SeriesDelete($id: ID!) {
    seriesDelete(id: $id)
}
`;
const SERIES_CREATE = gql`
mutation SeriesCreate($input: SeriesInput!, $active: Boolean) {
    seriesCreate(input: $input) { ${SERIES_FRAGMENT} }
}
`;

export const useSeries = () => {
    const { data, loading, error } = useQuery(ALL_SERIES, {
        fetchPolicy: 'cache-and-network'
    });

    return {
        series: data?.allSeries.map(s => new SeriesModel(s)),
        loading,
        error
    };
};

export const useSeriesBasic = () => {
    const { data, loading, error } = useQuery(ALL_SERIES_BASIC, {
        fetchPolicy: 'cache-and-network'
    });

    return {
        series: data?.allSeries.map(s => new SeriesModel(s)),
        loading,
        error
    };
};

export const useSeriesById = (id, active) => {
    const { data, loading, error, refetch, startPolling, stopPolling } = useQuery(SERIES_BY_ID, {
        variables: { id, active },
        fetchPolicy: 'cache-and-network'
    });

    return {
        series: data?.series ? new SeriesModel(data.series) : null,
        loading,
        error,
        refetch,
        startPolling,
        stopPolling
    };
};

export const useSeriesSummary = (id) => {
    const { data, loading, error } = useQuery(SERIES_SUMMARY, {
        variables: { id },
        fetchPolicy: 'cache-and-network'
    });

    return {
        series: data?.series ? new SeriesModel(data.series) : null,
        highlights: data?.highlights ? new HighlightsListModel(data.highlights) : null,
        summary: data?.summary ? new SummaryModel(data.summary) : null,
        statistics: data?.statistics ? new StatisticsListModel(data.statistics) : null,
        loading,
        error
    };
};

export const useEditSeries = () => {
    const [seriesUpdate, seriesUpdateMutation] = useMutation(SERIES_UPDATE);
    const [seriesDelete, seriesDeleteMutation] = useMutation(SERIES_DELETE, {
        refetchQueries: ['AllSeriesBasic']
    });

    return {
        update: (id, input, { onCompleted, onError } = {}) => seriesUpdate({
            variables: { id, input },
            onCompleted: data => onCompleted?.(data.seriesUpdate),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('seriesUpdate', error)
        }),
        remove: (id, { onCompleted, onError } = {}) => seriesDelete({
            variables: { id },
            onCompleted: data => onCompleted?.(data.seriesDelete),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('seriesDelete', error)
        }),

        loading: seriesUpdateMutation.loading || seriesDeleteMutation.loading
    };
};

export const useScoreboard = (id) => {
    const { data, loading, error } = useQuery(SCOREBOARD, {
        variables: { id, active: true },
        pollInterval: 1000
    });

    return {
        series: data?.series ? new SeriesModel(data.series) : null,
        highlights: data?.highlights ? new HighlightsListModel(data.highlights) : null,
        statistics: data?.statistics ? new StatisticsListModel(data.statistics) : null,
        loading,
        error
    };
};

export const useSeriesCreate = () => {
    const [seriesCreate, seriesCreateMutation] = useMutation(SERIES_CREATE, {
        refetchQueries: ['AllSeriesBasic']
    });

    return {
        create: (input, { onCompleted, onError } = {}) => seriesCreate({
            variables: { input },
            onCompleted: data => onCompleted?.(data.seriesCreate),
            onError: error => onError?.(new ErrorReader(error)) ?? console.error('seriesCreate', error)
        }),
        loading: seriesCreateMutation.loading
    };
};

export const useAutoRefresh = (init) => {
    const [autoRefresh, setAutoRefresh] = useState(init ?? false);
    return {
        autoRefresh,
        setAutoRefresh
    };
}