import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';

import ENV from '../env';
import LoginRedirecting from './LoginRedirecting';
import { routes } from '../routes.mjs';

// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app

export const Auth0ProviderWithHistory = ({ children }) => {
    const domain = ENV.AUTH0.DOMAIN;
    const clientId = ENV.AUTH0.CLIENT_ID;
    const audience = ENV.AUTH0.AUDIENCE;

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || routes.home);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience
            }}
        >
            {children}
        </Auth0Provider>
    );
};

export const ProtectedComponent = ({ component, ...propsForComponent }) => {
    const WrappedComponent = withAuthenticationRequired(component, { onRedirecting: () => <LoginRedirecting /> });
    return <WrappedComponent {...propsForComponent} />;
};

export const SessionComponent = ({ children, noAuth }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    return (
        <>
            {isLoading ? <span /> : 
                isAuthenticated ? children : noAuth
            }
        </>
    );
};