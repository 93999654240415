import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useForm, Controller } from 'react-hook-form';
import { useAuth, Permission } from '../auth/useAuth';

const EditEntityNameDialog = ({ entity, title, nameLabel, handleSave, handleDelete, handleCloseDialog }) => {
    const formControls = useForm({
        defaultValues: {
            name: entity?.name ?? ''
        }
    });

    const onSave = (data) => {
        handleCloseDialog();
        return handleSave(entity?._id, data);
    };
    const onDelete = () => {
        handleCloseDialog();
        return handleDelete(entity._id);
    };

    const canDelete = !!entity?._id;
    return (
        <EditDialog
            title={title}
            nameLabel={nameLabel}
            canDelete={canDelete}
            onDelete={onDelete}
            onSave={onSave}
            handleCloseDialog={handleCloseDialog}
            formControls={formControls}
        />
    );
};

export default EditEntityNameDialog;

export const EditDialog = ({ title, nameLabel, canDelete, onDelete, onSave, handleCloseDialog, formControls, children }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const { hasPermission } = useAuth();

    const { control, handleSubmit, formState: { isValid } } = formControls;
    return (
        <Dialog open={true} onClose={handleCloseDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSave)}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ my: 1 }} display='flex' flexDirection='column' gap={2}>
                        <Controller name='name' control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => (
                                <TextField {...field}
                                    name={`com.spadeschamp.${nameLabel.replace(' ', '_')}`}
                                    required
                                    fullWidth={true}
                                    label={nameLabel}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message} />
                            )} />
                        {children}
                    </Box>
                </DialogContent>
                <Box display='flex' justifyContent='space-between' sx={{ mx: 2, mb: 1 }}>
                    {canDelete && hasPermission(Permission.Delete)
                        ? <Button onClick={onDelete} color='error' startIcon={<DeleteIcon />}>Delete</Button>
                        : <span />
                    }
                    <Box display='flex' gap={1}>
                        <Button
                            variant='contained'
                            type='submit'
                            disabled={!isValid}
                        >Save</Button>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                    </Box>
                </Box>
            </form>
        </Dialog>
    );
};
