import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as RouterLink } from 'react-router-dom';

import { SessionComponent } from '../auth/Auth0Components';
import LoginButton from '../auth/LoginButton';
import LogoutButton from '../auth/LogoutButton';
import Logo from './Logo';
import { routes } from '../routes.mjs';

const FullscreenContext = createContext();
export const FullscreenNavbarProvider = ({ children }) => {
  const [navItems, setNavItems] = useState({ title: '', to: '/' });
  const [orientation, setOrientation] = useState(window.screen?.orientation?.type ?? '');

  useEffect(() => {
    const orientationChanged = (e) => {
      setOrientation(e.target.type);
    };

    window.screen?.orientation?.addEventListener('change', orientationChanged);
    return () => {
      window.screen?.orientation?.removeEventListener('change', orientationChanged);
    };
  }, [orientation, setOrientation]);

  return (
    <FullscreenContext.Provider value={{ navItems, setNavItems, orientation }}>
      {children}
    </FullscreenContext.Provider>
  );
};
export const useNavBar = () => {
  return useContext(FullscreenContext);
};

const BRAND_STYLE = {
  mr: 2,
  display: 'flex',
  fontWeight: 700,
  letterSpacing: '.3rem',
  color: 'inherit',
  textDecoration: 'none',
  flexGrow: 1
};

const LoginOrSignup = () => (
  <LoginButton size='small' />
);

const MainToolbar = () => (
  <>
    <Box sx={{ mr: 2 }}><Logo /></Box>
    <Typography variant='h6' noWrap component={RouterLink} to={routes.root} sx={BRAND_STYLE}>
      Spadeschamp
    </Typography>

    <SessionComponent noAuth={<LoginOrSignup />}>
      <LogoutButton />
    </SessionComponent>
  </>
);

const FullscreenToolbar = () => {
  const { navItems } = useNavBar();
  return (
    <Box display='flex' justifyContent='space-between' alignItems='center' flexGrow={1}>
      <Typography variant='h5'>{navItems.title}</Typography>
      <Button variant='outlined' color='inherit' component={RouterLink} to={navItems.to}>Exit</Button>
    </Box>
  );
};

const NavBar = () => {
  const location = useLocation();
  return (
    <AppBar position='static' sx={{ bgcolor: 'success.light' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          {location.pathname.startsWith('/fs/')
            ? <FullscreenToolbar />
            : <MainToolbar />
          }
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;